import { render, staticRenderFns } from "./Production.vue?vue&type=template&id=1d3d5a38&scoped=true"
import script from "./Production.vue?vue&type=script&lang=js"
export * from "./Production.vue?vue&type=script&lang=js"
import style0 from "./Production.vue?vue&type=style&index=0&id=1d3d5a38&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d3d5a38",
  null
  
)

export default component.exports