// user
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO'
export const UPDATE_USER_AUTHORIZE_PIC = 'UPDATE_USER_AUTHORIZE_PIC'
export const UPDATE_VIEWING_USER = 'UPDATE_VIEWING_USER'
export const UPDATE_FANS = 'UPDATE_FANS'
export const PUSH_CAMPAIGN_USER = 'PUSH_CAMPAIGN_USER'
export const UPDATE_NOTIFICATION_SETTINGS = 'UPDATE_NOTIFICATION_SETTINGS'
export const UPDATE_USER_FOLLOWED = 'UPDATE_USER_FOLLOWED'

// application
export const SHOW_CHARGE = 'SHOW_CHARGE'
export const HIDE_CHARGE = 'HIDE_CHARGE'
export const START_PULLING = 'START_PULLING'
export const STOP_PULLING = 'STOP_PULLING'
export const UPDATE_USE_TRANSITION = 'UPDATE_USE_TRANSITION'
export const UPDATE_EXPIRED_DATE = 'UPDATE_EXPIRED_DATE'
export const UPDATE_AUTHENTICATE = 'UPDATE_AUTHENTICATE'
export const UPDATE_ACCESS_TOKEN = 'UPDATE_ACCESS_TOKEN'
export const UPDATE_REFRESH_TOKEN = 'UPDATE_REFRESH_TOKEN'
export const UPDATE_OPENID = 'UPDATE_OPENID'
export const UPDATE_CHANNEL_NUMBER = 'UPDATE_CHANNEL_NUMBER'
export const UPDATE_CONFIGS = 'UPDATE_CONFIGS'
export const UPDATE_SEARCH_FOCUS = 'UPDATE_SEARCH_FOCUS'

// posts
export const INIT_MAIN_POSTS = 'INIT_MAIN_POSTS'
export const PUSH_MAIN_POST = 'PUSH_MAIN_POST'
export const UPDATE_POST = 'UPDATE_POST'
export const UNSHIFT_POST = 'UNSHIFT_POST'
export const UPDATE_DETAIL_POST = 'UPDATE_DETAIL_POST'
export const CLR_DETAIL_POST = 'CLR_DETAIL_POST'
export const STAR_POST = 'STAR_POST'
export const LIKE_POST = 'LIKE_POST'

export const INIT_DOG_POSTS = 'INIT_DOG_POSTS'
export const INIT_CAMPAIGN_POSTS = 'INIT_CAMPAIGN_POSTS'
export const PUSH_CAMPAIGN_POST = 'PUSH_CAMPAIGN_POST'
export const PUSH_DOG_POST = 'PUSH_DOG_POST'
export const UPDATE_DOG_POST = 'UPDATE_DOG_POST'
export const UNSHIFT_DOG_POST = 'UNSHIFT_DOG_POST'

export const PUSH_USER_POST = 'PUSH_USER_POST'
export const UPDATE_USER_POSTS = 'UPDATE_USER_POSTS'
export const UNSHIFT_USER_POST = 'UNSHIFT_USER_POST'
export const UPDATE_TIMESTAMP = 'UPDATE_TIMESTAMP'
export const POST_DISAPPEAR = 'POST_DISAPPEAR'

//  stars
export const UDPATE_ALBUMS = 'UPDATE_ALBUMS'
export const PUSH_ALBUMS = 'PUSH_ALBUMS'
export const UPDATE_PICTURES = 'UPDATE_PICTURES'
export const CLR_PICTURES = 'CLR_PICTURES'
export const CHANGE_STATUS = 'CHANGE_STATUS'
export const CHANGE_PICSTATUS = 'CHANGE_PICSTATUS'
export const CHANGE_PICCHECK = 'CHANGE_PICCHECK'


// channel
export const PUSH_CHANNEL = 'PUSH_CHANNEL'
export const UPDATE_CHANNEL_POSTS = 'UPDATE_CHANNEL_POSTS'
export const PUSH_CHANNEL_POSTS = 'PUSH_CHANNEL_POSTS'
export const UPDATE_CHANNEL_USERS = 'UPDATE_CHANNEL_USERS'
export const PUSH_CHANNEL_USERS = 'PUSH_CHANNEL_USERS'
export const UPDATE_FROM_CHANNEL = 'UPDATE_FROM_CHANNEL'
export const UPDATE_CHANNEL_ID = 'UPDATE_CHANNEL_ID'
export const UPDATE_VIEWING_CHANNEL = 'UPDATE_VIEWING_CHANNEL'
export const REMOVE_CHANNEL = 'REMOVE_CHANNEL'
export const UPDATE_MANAGEABLE_CHANNELS = 'UPDATE_MANAGEABLE_CHANNELS'

// uploader
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS'
export const CLEAR_PROGRESSES = 'CLEAR_PROGRESSES'
export const UPDATE_IMAGE_TOKEN = 'UPDATE_IMAGE_TOKEN'
export const UPDATE_VIDEO_TOKEN = 'UPDATE_VIDEO_TOKEN'

//postupload
export const UPDATE_IMAGE_TOKEN_POST = 'UPDATE_IMAGE_TOKEN_POST'
export const UPDATE_VIDEO_TOKEN_POST = 'UPDATE_VIDEO_TOKEN_POST'
export const UPDATE_PROGRESS_POST = 'UPDATE_PROGRESS_POST'
export const CLEAR_PROGRESSES_POST = 'CLEAR_PROGRESSES_POST'

//chat
export const UPDATE_UNREAD_COUNT = 'UPDATE_UNREAD_COUNT'
export const UPDATE_CHAT_SHOW = 'UPDATE_CHAT_SHOW'
export const UPDATE_PRIVATE_SHOW = 'UPDATE_PRIVATE_SHOW'
export const UPDATE_PRIVATECHAT = 'UPDATE_PRIVATECHAT'
export const UPDATE_PRIVATE_SHOW_COVER = 'UPDATE_PRIVATE_SHOW_COVER'
export const INIT_CHAT = 'INIT_CHAT'
export const PRIVATE_INIT_CHAT = 'PRIVATE_INIT_CHAT'