<template>
  <div class="post-container">
    <div class="left">
      <img
        :src="cover.thum"
        alt="cover">
    </div>

    <div class="right">
      <h2>{{ localeTitle }}</h2>
      <p v-if="post.content">
        {{ post.content }}
      </p>

      <footer>
        <span class="counts">
          <div
            v-if="pictureCount > 0"
            class="picture">
            <img src="@/assets/icon_web_h5_ImageNumber_grey.png">
            {{ pictureCount }}
          </div>
          <div
            v-if="videoCount > 0"
            class="video">
            <img src="@/assets/icon_web_h5_VideoNumber_grey.png">
            {{ videoCount }}
          </div>
        </span>

        <div class="price">
          <h3 v-show="post.price === 0">
            {{ price }}
          </h3>

          <h3 v-show="post.price !== 0 && !onSale">
            <span>{{ localPriceText }}</span>
          </h3>

          <h3 v-show="post.price !== 0 && onSale">
            <span>
              <span class="linethrough">{{ localPriceText }}</span>
              {{ discountedPriceText }}
            </span>
          </h3>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { useArtworkPresent } from '@/composables/artwork_present'
import { useCurrentCurrency } from '@/composables/currency'
import { useFormattedString } from '@/composables/formatted_string'
import { MediaType } from '@/utils/defines';

export default {
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { currency } = useCurrentCurrency()
    const { localPriceText, price, onSale, discountedPriceText, localeTitle } = useArtworkPresent(props.post._id, currency, props.post)

    return {
      localPriceText,
      price,
      onSale,
      discountedPriceText,
      ...useFormattedString(),
      localeTitle,
    }
  },
  computed: {
    createdAt () {
      return this.formatTimestamp(this.post.createdAt)
    },
    cover () {
      return this.post.pictures.filter(picture => picture.canBrowse)[0] || this.post.pictures[0]
    },
    videoCount () {
      return this.post.pictures.filter(picture => picture.type === MediaType.VIDEO).length
    },
    pictureCount () {
      return this.post.pictures.filter(picture => picture.type === MediaType.IMAGE).length
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/assets/base.less';

.post-container {
  background: white;
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
  height: 85px;
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;

  .left {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      text-align: left;
      width: 60px;
      height: 60px;
      object-fit: cover;
    }
  }

  .right {
    position: relative;
    height: 100%;
    flex: 1;
    overflow: hidden;

    h2 {
      font-size: 17px;
      font-weight: 500;
      color: @black;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      color: @minor;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 30px;
    }

    footer {
      position: absolute;
      bottom: -0.5rem;
      display: flex;
      width: 100%;

      .price {
        margin-left: auto;
        text-align: right;

        .linethrough {
          text-decoration: line-through;
          color: @grey;
        }

        h3 {
          color: @yellow;
          font-size: 14px;
          font-weight: 500;
          margin-top: 0.2em;
          display: inline-block;

          span {
            display: block;
          }
        }
      }

      .counts {
        display: flex;
        gap: 1rem;

        div {
          color: @grey;
          font-size: 14px;
          font-weight: 500;

          img {
            display: inline-block;
            vertical-align: middle;
            width: 13px;
            height: 13px;
            margin-top: -1px;
          }
        }
      }
    }
  }
}
</style>
