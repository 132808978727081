import { api } from './base'

export const exchange = async function (base) {
  try {
    const res = await api.get('/configs/exchange', {
      params: {
        base,
      }
    })

    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}