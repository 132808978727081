<template>
  <div class="container">
    <div class="group">
      <mt-cell
        v-if="!currentUser.authed"
        :title="$t('applyVerify')"
        is-link
        class="hoverable"
        @click.native="$router.push('/i/publisher/verify')"></mt-cell>
      <mt-cell
        v-if="currentUser.authed"
        :title="$t('publish')"
        is-link
        class="hoverable"
        @click.native="$router.push('/i/publisher/publish')"></mt-cell>
      <!-- <mt-cell
        title="我的不良度"
        is-link
        @click.native="$router.push('/i/publisher/untrust')"></mt-cell> -->
      <mt-cell
        v-if="currentUser.authed"
        :title="$t('contactBuyers')"
        is-link
        class="hoverable"
        @click.native="$router.push('/i/publisher/requesters')"></mt-cell>
      <mt-cell
        v-if="currentUser.authed"
        :title="$t('artworkCategories')"
        is-link
        class="hoverable"
        @click.native="$router.push('/i/publisher/tags')"></mt-cell>
    </div>
    <div class="group">
      <mt-cell
        v-if="currentUser.authed"
        :title="$t('openCircle')"
        is-link
        class="hoverable"
        @click.native="$router.push('/i/publisher/circles/apply')"></mt-cell>
      <mt-cell
        :title="$t('promote')"
        is-link
        class="hoverable"
        @click.native="$router.push('/i/publisher/promote')"></mt-cell>
      <mt-cell
        :title="$t('rules')"
        is-link
        class="hoverable"
        @click.native="$router.push('/rules')"></mt-cell>
      <mt-cell
        :title="$t('reportCenter')"
        is-link
        class="hoverable"
        @click.native="$router.push('/report-center')">
      </mt-cell>
      <!-- <mt-cell
        title="围观盗图狗"
        is-link
        @click.native="$router.push('/dogs')"
      /> -->
    </div>

    <div class="group">
      <mt-cell
        :title="$t('channelManagement')"
        is-link
        class="hoverable"
        @click.native="$router.push('/i/publisher/channels')"></mt-cell>
      <!-- <mt-cell
        is-link
        title="上传列表"
        @click.native="$router.push('/i/publisher/tasks')"
      /> -->
    </div>
  </div>
</template>

<script>
import { useResponsive } from '@/composables/responsive'
import { useCurrentUser } from '@/composables/user_data'
import { t } from '@/translations'


export default {
  metaInfo: {
    title: t('creator'),
  },
  setup () {
    return {
      ...useResponsive(),
      ...useCurrentUser(),
    }
  },
}
</script>

<style scoped lang="less">
.container {
  padding-top: 20px;

  .group {
    margin-bottom: 20px;
  }
}

.icon {
  margin-top: 12px;
  fill: #fff;
}
</style>
