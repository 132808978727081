<template>
  <sidebar
    :visible.sync="visible"
    position="bottom"
    class="p-sidebar-lg">
    <div class="container">
      <header>
        <input-text
          v-model="keyword"
          :placeholder="$t('search')"
          type="text"></input-text>
      </header>

      <div class="artworks">
        <div
          v-for="artwork in artworks.filter(artwork => artwork)"
          :key="artwork._id"
          class="selection touchable hoverable"
          @click="toggleSelection(artwork._id)">
          <div>
            <img
              v-if="selectedArtworkIds.includes(artwork._id)"
              src="@/assets/ic-page-switch-selected@2x.png">
            <img
              v-else
              src="@/assets/ic-page-switch-normal@2x.png">
          </div>

          <post-simple-item :post="artwork"></post-simple-item>
        </div>

        <load-more-button
          :busy="searching"
          :has-more="hasMore"
          @trigger="loadMore"></load-more-button>
      </div>

      <footer>
        <pr-button
          type="primary"
          :loading="loading"
          @click.native="confirm">
          {{ $t('confirm') }}
        </pr-button>
      </footer>
    </div>
  </sidebar>
</template>

<script>
//@ts-check
import Sidebar from 'primevue/sidebar'
import { computed, onMounted, ref, watch } from 'vue'
import PrButton from '@/components/PRButton.vue'
import { useArtworkStore } from '@/pinia/artwork'
import PostSimpleItem from '@/components/PostSimpleItem.vue'
import InputText from 'primevue/inputtext'
import { searchSelfPosts } from '@/api/post'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import { watchDebounced } from '@vueuse/core'
import { useToast } from '@/composables/toast'


const limit = 5

export default {
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    loading: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  setup (props, { emit }) {
    const artworkIds = ref([])
    const selectedArtworkIds = ref([])
    const artworkStore = useArtworkStore()
    const visible = ref(props.value)
    const keyword = ref('')
    const page = ref(1)
    const searching = ref(false)
    const hasMore = ref(true)
    const { Toast } = useToast()

    watch(props, () => {
      visible.value = props.value
    })

    watch(visible, () => {
      emit('input', visible.value)
    })

    watchDebounced(keyword, () => {
      page.value = 1
      artworkIds.value = []
      searchMyArtworks()
    })

    const loadMore = async function () {
      page.value += 1
      const beforeCount = artworkIds.value.length
      await searchMyArtworks()
      const afterCount = artworkIds.value.length
      if (beforeCount === afterCount) {
        hasMore.value = false
      } else {
        hasMore.value = true
      }
    }

    const searchMyArtworks = async function () {
      try {
        searching.value = true
        const rawPosts = await searchSelfPosts(keyword.value, limit, page.value)
        rawPosts.forEach(post => {
          artworkStore.cacheArtwork(post)
        })
        artworkIds.value = [...artworkIds.value, ...rawPosts.map(post => post._id)]
      } catch (err) {
        Toast({
          message: err.message,
        })
      } finally {
        searching.value = false
      }
    }

    const toggleSelection = function (artworkId) {
      if (!selectedArtworkIds.value.includes(artworkId)) {
        selectedArtworkIds.value.push(artworkId)
      } else {
        selectedArtworkIds.value.splice(selectedArtworkIds.value.indexOf(artworkId), 1)
      }
    }

    const artworks = computed(() => {
      return artworkIds.value.map(artworkId => artworkStore.artworks[artworkId])
    })

    const confirm = function () {
      emit('confirm', selectedArtworkIds.value)
    }

    onMounted(() => {
      page.value = 1
      artworkIds.value = []
      searchMyArtworks()
    })

    return {
      artworkIds,
      selectedArtworkIds,
      confirm,
      artworks,
      visible,
      searchMyArtworks,
      keyword,
      page,
      searching,
      hasMore,
      loadMore,
      toggleSelection
    }
  },
  components: {
    Sidebar,
    PrButton,
    PostSimpleItem,
    InputText,
    LoadMoreButton,
  }
}
</script>


<style scoped>
.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 0.5rem;
}

.artworks {
  flex: 1;
  overflow-y: auto;
}

.selection {
  display: flex;
  align-items: center;
  width: 100%;
}

.selection img {
  width: 2rem;
}

.selection .post-container {
  flex: 1;
}

button, input {
  width: 100%;
}
</style>