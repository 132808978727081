<template>
  <vue-plyr
    v-if="resolutions.length > 0"
    ref="vuePlyrRef"
    :options="{
      quality: {
        default: Number(defaultResolution),
        options: resolutions.map(resolution => {
          return Number(resolution)
        }),
        forced: true,
        onChange: setResolution,
      },
    }">
    <video
      ref="videoRef"
      crossorigin="true"
      playsinline
      :poster="media.thum"
      @play="onplay">
    </video>
  </vue-plyr>
</template>


<script>
//@ts-check
import 'vue-plyr/dist/vue-plyr.css'
import { onMounted, ref, watch, computed, onUnmounted } from 'vue'
// @ts-ignore
import { reusePendingPromise } from 'reuse-pending-promise'
import localforage from 'localforage'
import { useCurrentUser } from '@/composables/user_data'
import { useApplication } from '@/pinia/application'
import Hls from 'hls.js'
import { useToast } from '@/composables/toast'
import { getResolutions } from '@/api/media'
import { useArtworkPurchase } from '@/composables/artwork_purchase'
import { getCDNHost } from '@/utils'
import { useI18n } from 'vue-i18n-composable'

const defaultResolution = '480'

export default {
  props: {
    media: {
      type: Object,
      required: true
    },
    paid: {
      type: Boolean,
      required: false,
      default: false,
    },
    artworkId: {
      type: String,
      required: true,
    }
  },
  setup (props) {
    const videoRef = ref(null)
    const vuePlyrRef = ref(null)
    const accessToken = ref('')
    const { authenticated } = useCurrentUser()
    const store = useApplication()
    const resolution = ref(defaultResolution)
    const { Toast } = useToast()
    const resolutions = ref([])
    const { buyPost } = useArtworkPurchase(props.artworkId)
    const { t } = useI18n()
    let _hls = null

    const playable = computed(() => {
      return props.media.canBrowse || props.paid
    })

    watch(videoRef, () => {
      initStreamingIfNeeded()
    })
    watch(vuePlyrRef, () => {
      initStreamingIfNeeded()
    })
    watch(props, () => {
      initStreamingIfNeeded()
    })

    const initStreamingIfNeeded = function () {
      if (videoRef.value && vuePlyrRef.value && playable.value) {
        initHLS()
      }
    }

    const onplay = async function (event) {
      if (!authenticated.value) {
        store.showLogin()
        event.preventDefault()
        vuePlyrRef.value.player.pause()
        return
      }

      if (!props.paid && !props.media.canBrowse) {
        event.preventDefault()
        vuePlyrRef.value.player.pause()
        return buyPost({ postId: props.artworkId })
      }
    }

    const _initHLS = async function () {
      accessToken.value = await localforage.getItem('accessToken')

      if (!accessToken.value) {
        return
      }

      const url = new URL(props.media.url)
      const key = url.searchParams.get('path')

      const m3u8Url = new URL('/hls', getCDNHost())
      m3u8Url.searchParams.append('key', key)
      m3u8Url.searchParams.append('artwork_id', props.artworkId)
      m3u8Url.searchParams.append('resolution', resolution.value)

      if (accessToken.value) {
        m3u8Url.searchParams.append('token', accessToken.value)
      }

      if (Hls.isSupported()) {
        const hls = new Hls({
          fragLoadingTimeOut: 8 * 1000 * 60, // 8 minutes
          fragLoadingMaxRetryTimeout: 8 * 1000 * 60,
          levelLoadingRetryDelay: 8 * 1000 * 60,
          liveBackBufferLength: Infinity,
          xhrSetup: function (xhr, url) {
            xhr.setRequestHeader('Access-Token', accessToken.value)
          }
        })

        _hls = hls

        hls.loadSource(m3u8Url.toString())
        hls.attachMedia(videoRef.value) 

        hls.on(Hls.Events.ERROR, function (event, data) {
          if (data.details === 'fragParsingError') {
            vuePlyrRef.value.player.currentTime += 10 // segment duration            
          }
          if (data.fatal) {
            Toast({
              message: `${data.details} ${t('tryRefreshPage')}`,
            })
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                // try to recover network error
                console.error('Network error encountered', data)
                break
              case Hls.ErrorTypes.MEDIA_ERROR:
                console.error('Media error encountered', data)
                break
              default:
                // cannot recover
                hls.destroy()
                break
            }
          }
        })
      } else {
        videoRef.value.src = m3u8Url.toString()
      }
    }

    const initHLS = reusePendingPromise(_initHLS)

    const initResolutions = async function () {
      try {
        resolutions.value = await getResolutions(props.media._id)
      } catch (err) {
        Toast({
          message: err.message,
        })
      }
    }

    const setResolution = function (newResolution) {
      resolution.value = String(newResolution)
      if (videoRef.value && vuePlyrRef.value && playable.value) {
        initHLS()
      }
    }

    onMounted(() => {
      initStreamingIfNeeded()
      initResolutions()
    })

    onUnmounted(() => {
      if (_hls) {
        _hls.destroy()
        _hls = null
      }
    })

    return {
      onplay,
      videoRef,
      vuePlyrRef,
      playable,
      resolutions,
      setResolution,
      defaultResolution
    }
  }
}
</script>