import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions/'
import * as getters from './getters'
import album from './modules/album'
import chat from './modules/chat'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  actions,
  getters,
  modules: {
    album,
    chat,
  },
  strict: debug
})
