import * as types from '../mutation-types'
import Vue from 'vue'

const state = {
  UnreadCount: 0,
  chatShow: false,
  privateShow: false,
  privateId: '',
  initChat: false,
  privateinitChat: false
}

const mutations = {
  [types.UPDATE_UNREAD_COUNT] (state, count) {
    state.UnreadCount = count
  },
  [types.UPDATE_CHAT_SHOW] (state) {
    state.chatShow = !state.chatShow
  },
  [types.UPDATE_PRIVATE_SHOW] (state,user) {
    
    state.privateId = user._id
    //console.info(state.privateId)
  },
  [types.UPDATE_PRIVATECHAT] (state) {
    state.privateId = ''
  },
  [types.UPDATE_PRIVATE_SHOW_COVER] (state,user) {
    state.chatShow = true
  },
  [types.INIT_CHAT] (state,flag) {
    state.initChat = flag
  },
  [types.PRIVATE_INIT_CHAT] (state,user) {
    state.privateinitChat = user
  },

}

export default {
  state,
  mutations
}
