//@ts-check
import { ref } from "vue"
import { useCirclePresent } from "./circle_present"
import { whenever } from "@vueuse/core"
import { updateCircle } from "@/api/circle"
import { useToast } from "./toast"
import { useCurrentCurrency } from "./currency"
import { useRoute, useRouter } from "vue-router/composables"
import { useI18n } from "vue-i18n-composable"


export const useCircleEdit = ({ coverUrl }) => {
  const { currency } = useCurrentCurrency()
  const circleId = useRoute().params.circleId
  const { circle } = useCirclePresent(currency, { fromNetwork: true })
  const title = ref('')
  const description = ref('')
  const introduction = ref('')
  const loading = ref(false)
  const router = useRouter()
  const { Toast } = useToast()
  const { t } = useI18n()

  whenever(circle, () => {
    title.value = circle.value.title
    description.value = circle.value.description
    introduction.value = circle.value.introduction
    coverUrl.value = circle.value.coverUrl
  })

  const update = async function () {
    try {
      loading.value = true
      await updateCircle(circleId, {
        title: title.value,
        description: description.value,
        introduction: introduction.value,
        coverUrl: coverUrl.value,
      })
      Toast(t('success'), 'ok')
      router.push(`/circles/${circleId}`)
    } catch (err) {
      Toast(err.message)
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    title,
    description,
    introduction,
    update,
    coverUrl,
  }
}