<template>
  <div class="page">
    <div class="container">
      <div>
        <div class="avatar-container">
          <div class="avatar-wrap">
            <img
              v-show="!uploading"
              :src="currentUser.avatarURL"
              class="avatar"
              alt="avatar">
            <i
              v-show="uploading"
              class="pi pi-spin pi-spinner"
              style="font-size: 2rem;">
            </i>
            <img
              class="upload touchable hoverable "
              src="@/assets/Add_HeadImage.png"
              alt="upload"
              @click="pickAvatarImage">
            
            <input
              v-show="false"
              ref="avatarInputRef"
              type="file"
              accept="image/*"
              @change="onFilePicked">
          </div>

          <mt-progress
            v-show="uploadProgress !== 0 && uploadProgress !== 100"
            :value="uploadProgress">
          </mt-progress>
        </div>

        <mt-field
          v-model="nickname"
          :label="$t('nickname')"
          :placeholder="$t('inputNickname')"></mt-field>
        
        <mt-field
          v-if="currentUser.authed"
          v-model="username"
          :label="$t('username')"
          placeholder="username">
          <span>.pero.app</span>
        </mt-field>
        
        <mt-cell
          :title="$t('gender')">
          <select v-model="gender">
            <option :value="0">{{ $t('secret') }}</option>
            <option :value="1">{{ $t('female') }}</option>
            <option :value="2">{{ $t('male') }}</option>
          </select>
        </mt-cell>
        
        <mt-field
          v-model="motto"
          :label="$t('brief')"
          type="textarea"
          rows="3"></mt-field>

        <pr-button
          type="primary"
          @click.native="saveProfile">
          {{ $t('save') }}
        </pr-button>
      </div>

      <hr>
      
      <div>
        <mt-cell
          is-link
          :title="$t('setAutoreply')"
          to="/i/edit/autoreply"></mt-cell>
        <mt-cell
          is-link
          :title="$t('updatePhone')"
          to="/i/edit/phone"></mt-cell>
      </div>
    </div>
  </div>
</template>

<script>
import PrButton from '@/components/PRButton.vue'
import { useResponsive } from '@/composables/responsive'
import { useUserProfile } from '@/composables/user_edit'
import { useCurrentUser } from '@/composables/user_data'
import { t } from '@/translations'
import { useUploadAvatar } from '@/composables/upload_avatar'


export default {
  metaInfo: {
    title: t('updateProfile')
  },
  setup () {
    return {
      ...useResponsive(),
      ...useUserProfile(),
      ...useCurrentUser(),
      ...useUploadAvatar(),
    }
  },
  components: {
    PrButton,
  }
}
</script>

<style lang="less" scoped>
@import '@/assets/base.less';

.page {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: 2em;
}

.container {
  padding-top: 30px;
  display: flex;
  justify-content: center;
  width: 100%;

  > div {
    width: 100%;
    margin-right: 2em;
    max-width: 200em;
  }
}

.avatar-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 2em;

  .avatar-wrap {
    width: 6em;
    height: 6em;
    border-radius: 3em;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .avatar {
      width: 100%;
      height: 100%;
    }

    img.upload {
      width: 2em;
      height: 2em;
      position: absolute;
      bottom: -0.5em;
      right: -0.5em;
    }
  }
}

@media @phone {
  .page {
    padding: 1em;
    box-sizing: border-box;
  }

  .container {
    flex-wrap: wrap;

    > div {
      margin-right: 0;
    }
  }
}

button {
  display: block;
  margin: 40px auto;
  width: 90%;
}
</style>
