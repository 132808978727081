import { onMounted, ref } from "vue"
import { useCurrentUser } from "./user_data"
import userapi from "@/api/user"
import { Toast, Indicator } from 'mint-ui'
import { useI18n } from "vue-i18n-composable"
import { useUserStore } from "@/pinia/user"
import { isValidDomain } from "@/utils"
import { useToast } from "./toast"


export const useUserProfile = function () {
  const nickname = ref('')
  const gender = ref(0)
  const birthday = ref(0)
  const motto = ref('')
  const username = ref('')
  const { authenticated, currentUser } = useCurrentUser()
  const { t } = useI18n()
  const userStore = useUserStore()
  const { Toast } = useToast()

  const saveProfile = async function () {
    try {
      if (username.value) {
        if (username.value.includes(':')) {
          throw new Error('please do not input http:// or https://')
        }
  
        if (!isValidDomain(`${username.value}.pero.app`)) {
          throw new Error(`${username.value}.pero.app is not a valid domain`)
        } 
      }

      Indicator.open()

      const doc = {
        nickname: nickname.value,
        gender: gender.value,
        birthday: birthday.value,
        motto: motto.value,
        username: username.value,
      }

      await userapi.update(currentUser.value._id, doc)

      for (const key of Object.keys(doc)) {
        userStore.patchSelf(key, doc[key])
      }
      Toast(t('success'), 'ok')
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      Indicator.close()
    }
  }


  onMounted(() => {
    if (authenticated.value) {
      nickname.value = currentUser.value.nickname
      gender.value = currentUser.value.gender
      birthday.value = currentUser.value.birthday
      motto.value = currentUser.value.motto
      username.value = currentUser.value.username
    }
  })


  return {
    nickname,
    gender,
    birthday,
    motto,
    saveProfile,
    username,
  }
}