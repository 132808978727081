//@ts-check
import { useAuthenticator } from "@/pinia/authenticator"
import { computed } from "vue"



export const useCurrentUser = function () {
  const authenticator = useAuthenticator()

  const currentUser = computed(() => authenticator.currentUser)
  const authenticated = computed(() => authenticator.authenticated)

  return {
    currentUser,
    authenticated,
  }
}