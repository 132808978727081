<template>
  <div
    class="container">
    <login-cover v-if="!authenticated" />
    <div
      v-if="authenticated"
      class="left">
      <input
        v-model="keyword"
        @keypress="enter">
      <p
        v-for="(album,index) in albums"
        v-show="!search"
        :key="index"
        class="hoverable touchable"
        :class="{back: album.ischeck}"
        @click="pic(index, album.user._id, false)">
        {{ album.user.nickname }}
      </p>
      <p
        v-show="albums.length > 0 && hasMore && !search"
        class="bule hoverable touchable"
        @click="loadMore">
        {{ $t('loadmore') }}
      </p>
      <p
        v-show="albums.length > 0 && !hasMore && !search"
        class="grey hoverable touchable"
        @click="loadMore">
        {{ $t('nomore') }}
      </p>
      <p
        v-for="(album,index) in searchAlbum"
        v-show="search"
        :key="index"
        class="hoverable touchable"
        :class="{back: album.ischeck}"
        @click="pic(index, album.user._id, true)">
        {{ album.user.nickname }}
      </p>
    </div>
    <div
      v-if="authenticated"
      class="right">
      <div class="content">
        <div
          v-for="(picture, index) in pictures"
          :key="index">
          <img
            v-if="picture.type === MediaType.IMAGE"
            :src="picture.thum"
            class="product hoverable"
            @click="view(index)">
          <img
            v-if="picture.type === MediaType.VIDEO"
            :src="picture.thum"
            class="product hoverable"
            @click="view(index)">
          <img
            v-if="picture.type === MediaType.VIDEO"
            class="video"
            src="@/assets/video_tag.png">
          <img
            v-show="picture.showcheck && !picture.ischeck"
            class="edit"
            src="@/assets/Select_Off.png">
          <img
            v-show="picture.showcheck && picture.ischeck"
            class="edit"
            src="@/assets/Select_On.png">
        </div>
        <div
          v-show="pictures.length > 0"
          class="hoverable touchable load"
          @click.prevent="more">
          <span>{{ $t('viewMore') }}</span>
        </div>
      </div>
      <div class="bottom">
        <span
          v-show="!editprocess && pictures.length>0"
          class="hoverable touchable"
          @click="edit(true)">{{ $t('edit') }}</span>
        <span
          v-show="editprocess"
          class="red hoverable touchable"
          @click="remove">{{ $t('delete') }}({{ dealPicture }})</span>
        <span
          v-show="editprocess"
          class="cancel hoverable touchable"
          @click="edit(false)">{{ $t('cancel') }}</span>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import LoginCover from '@/components/LoginCover'
import api from '@/api/media'
import { useResponsive } from '@/composables/responsive'
import { useCurrentUser } from '@/composables/user_data'
import { useApplication } from '@/pinia/application'
import { useToast } from '@/composables/toast'
import { MediaType } from '@/utils/defines'

const limit = 10

export default {
  metaInfo: {
    title: 'My Stars | Perohub',
  },
  setup () {
    const application = useApplication()

    return {
      ...useResponsive(),
      ...useCurrentUser(),
      openBrowser: application.openBrowser,
      ...useToast(),
      MediaType,
    }
  },
  data () {
    return {
      busy: false,
      page: 1,
      hasMore: true,
      searchAlbum: [],
      search: false,
      albumId: '',
      keyword: '',
      editprocess: false,
    }
  },
  computed: {
    ...mapGetters({
      albums: 'albums',
      pictures: 'pictures'
    }),

    dealPicture () {
      let count = 0
      this.pictures.forEach(album => {
        if (album.ischeck) {
          count++
        }
      })
      return count
    }
  },
  watch: {
    authenticated (value) {
      if (value) {
        this.loadMore()
      }
    },
    albums (value) {
      //value[0].ischeck = true
      if(this.pictures.length === 0) {
        setTimeout(()=>{
          this.pic(0, value[0].user._id)
        },20)
      }
      
      
    }
  },
  methods: {
    ...mapActions([
      'fetchAlbums',
      'fetchPictures',
      'clearPictures'
    ]),
    remove () {
      if (this.dealPicture === 0) {
        this.showToast({
          message: '请选择您要删除的图片'
        }, 'info')
        return
      }
      let pictureIds = []
      this.pictures.forEach(album => {
        if (album.ischeck) {
          pictureIds.push(album._id)
        }
      })


      //console.info(pictureIds)
      api
        .albumsDelete(pictureIds)
        .then(albums => {
          console.info(albums)
          this.showToast({
            message: '取消收藏成功'
          }, 'ok')
          this.clearPictures()
          this.fetchPictures({ ownerId:  this.albumId})
          this.edit(false)
        })
        .catch(err => {
         
          this.showToast({
            message: err.message ? err.message : err
          })
        })

    },
    edit (flag) {
      this.editprocess = flag
      this.$store.commit('CHANGE_PICSTATUS',flag)
    },
    enter (evt) {
      let charCode = (evt.which) ? evt.which : evt.keyCode
      if (charCode ===13) {
        this.search = true
        this.albumsSearch(this.keyword)
      }

      if (this.keyword == '') {
        this.search = false
      }
    },
    pic (index, albumId, search) {
      this.clearPictures()
      
      if (search && this.searchAlbum.length > 0) {
        this.searchAlbum.forEach(album => {
          album.ischeck = false
        })

        this.searchAlbum[index].ischeck = true

      } else {
        this.$store.commit('CHANGE_STATUS',index)
      }
      this.albumId = albumId
      this.fetchPictures({ ownerId:  albumId})
    },
    more () {
      const timestamp = this.pictures[this.pictures.length - 1].starredAt

      this.fetchPictures({ ownerId: this.albumId, timestamp })
    },
    view (index) {
      if (this.editprocess) {
        this.$store.commit('CHANGE_PICCHECK',index)
      } else {
        this.openBrowser(this.pictures, index)
      }
    },
    loadMore () {
      if (!this.hasMore) {
        return
      }
      this.busy = true
      this.fetchAlbums({ page: this.page, limit }).then(hasMore => {
        this.busy = false
        this.page++
        this.hasMore = hasMore
      })
    },
    albumsSearch (keyword) {
      api
        .albumsSearch(keyword)
        .then(albums => {
          //console.info(album)
          albums.forEach(album => {
            album.ischeck = false
          })

          this.searchAlbum = albums

        })
        .catch(err => {
          this.busy = false

          console.error(err)

          this.showToast({
            message: err.message ? err.message : err
          })
        })
    },
  },
  mounted () {
    this.page = this.albums.length / limit + 1
    if (this.authenticated && this.albums.length === 0) {
      this.loadMore()
    }
  },
  components: {
    LoginCover,
  }
}
</script>


<style lang="less" scoped>

img {
  object-fit: cover;
}

.container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  display: flex;
  padding-bottom: 80px;


  .left {
    width: 130px;
    text-align: center;
    margin-right: 40px;

    input {
      display: inline-block;
      width: 110px;
      height: 30px;
      border-radius: 15px;
      background: #ffffff url('@/assets/ic_tabs_discovery@2x.png') no-repeat;
      background-size: 20px 20px;
      background-position: 10px center;
      border: none;
      outline: none;
      padding-left: 10px;
      font-size: 14px;
      text-indent: 25px;
      border: 1px solid #f1f1f1;
    }


    p {
      width: 120px;
      height: 30px;
      border-radius: 15px;
      background-color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 30px;
      letter-spacing: normal;
      text-align: center;
      color: #9b9b9b;
      margin-top: 6px;
      margin-left: auto;
      margin-right: auto;
      white-space:nowrap; 
      text-overflow:ellipsis; 
      overflow:hidden;
      padding-left: 14px;
      padding-right: 14px;
      box-sizing: border-box;

      &.back {
        background-color: #f1f1f1;
      }

      &.bule {
        border: 1px solid #55caff;
        color: #55caff;
      }

      &.grey {
        border: 1px solid #f1f1f1;
      }

    }
  }

  .right {
    flex: 1;

    .content {
      display: flex;
      flex-wrap: wrap;

      div {
        width: 114px;
        height: 114px;
        margin-right: 2px;
        margin-bottom: 2px;
        position: relative;
        .product {
          width: 114px;
          height: 114px;
          
        }

        .video {
          position: absolute;
          width: 50px;
          height: 50px;
          left: 28%;
          top: 28%;
        }

        .edit {
          width: 20px;
          height: 20px;
          position: absolute;
          right: 1px;
          top: 1px;
        }
      }

      .load {
        border: solid 1px #55caff;
        display: flex;
        justify-content:center;
        align-items:center;
        box-sizing: border-box;

        span {
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: center;
          color: #55caff;

        }
      }
    }

    .bottom {
      text-align: center;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #9b9b9b;
      margin-top: 20px;
      margin-bottom: 30px;
      

      span {
        width: 120px;
        height: 30px;
        border-radius: 15px;
        background-color: #ff5579;
        display: inline-block;
        line-height: 30px;
        color: #9b9b9b;
        background-color: #f1f1f1;

        &.cancel {
          border: solid 1px #cfcfcf;
        }

        &.red {
          background-color: #ff5579;
          color: white;
          margin-right: 6px;
        }
      }
    }
  }
}

</style>
