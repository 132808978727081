<template>
  <div class="container">
    <div
      v-for="post in posts"
      :key="post._id"
      class="post"
      :class="{ selected: post._id === selectedPostId }"
      @click="selectedPostId = post._id"
    >
      <div class="left">
        <img
          v-show="post._id === selectedPostId"
          src="@/assets/Select_On.png"
        >
        <img
          v-show="post._id !== selectedPostId"
          src="@/assets/Select_Off.png"
        >
      </div>
      <h1>{{ post.title }}</h1>
      <p>{{ post.content || '无文字内容' }}</p>
    </div>

    <load-more-button
      :busy="busy"
      :has-more="hasMore"
      :trigger="getPosts"
    />

    <img
      class="common-touchable contribute"
      src="@/assets/icon_post_channel_contribute.png"
      alt="contribute"
      @click="contribute"
    >
  </div>
</template>

<script>
import contribution from '@/api/contribute'
import api, { getUserPromotedPosts } from '@/api/post'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import { mapGetters } from 'vuex'
import { useToast } from '@/composables/toast'


export default {
  beforeRouteEnter (to, _, next) {
    document.title = `投稿到${to.query.title}`
    next()
  },
  setup () {
    return {
      ...useToast(),
    }
  },
  data () {
    return {
      posts: [],
      timestamp: new Date().getTime(),
      busy: false,
      page: 1,
      hasMore: true,
      selectedPostId: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'currentUser',
      authenticated: 'authenticated'
    })
  },
  watch: {
    authenticated (value) {
      if (value) {
        this.getTopPosts()
        this.getPosts()
      }
    }
  },
  mounted () {
    if (this.authenticated) {
      this.getTopPosts()
      this.getPosts()
    }
  },
  methods: {
    getTopPosts () {
      getUserPromotedPosts(this.user._id)
        .then(posts => {
          posts.reverse().forEach(post => this.posts.unshift(post))
        })
        .catch(err => {
          console.error(err)

          this.showToast({
            message: err,
          })
        })
    },
    getPosts () {
      this.busy = true

      const userId = this.user._id
      const timestamp = this.timestamp

      api
        .getPosts({ timestamp, limit: 10, userId }, 'old')
        .then(posts => {
          this.busy = false

          if (posts.length === 0) {
            this.hasMore = false
          } else {
            posts.forEach(post => this.posts.push(post))
            this.timestamp = posts[posts.length - 1].createdAt
          }
        })
        .catch(err => {
          console.error(err)

          this.busy = false

          this.showToast({
            message: err,
          })
        })
    },
    contribute () {
      const topicId = this.$route.params.topicId
      const postId = this.selectedPostId

      contribution
        .contributeToTopic(topicId, postId)
        .then(() => {
          this.showToast({
            message: '投稿成功'
          }, 'ok')
        })
        .catch(err => {
          console.error(err)

          this.showToast({
            message: err.message ? err.message : err
          })
        })
    }
  },
  components: {
    LoadMoreButton
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.post {
  padding: 1em;
  padding-left: 3em;
  box-sizing: border-box;
  position: relative;

  .left {
    display: block;
    height: 100%;
    width: 20px;
    float: left;
    position: absolute;
    left: 1em;
    top: 1em;

    img {
      width: 20px;
    }
  }


  h1 {
    font-size: 17px;
    font-weight: 500px;
    color: @black;
  }

  p {
    font-size: 13px;
    color: @black;
  }

  &.selected {
    background: @border;
  }
}

.contribute {
  position: fixed;
  right: 16px;
  bottom: 16px;
  z-index: 11;
  height: 64px;
  width: 64px;
}
</style>
