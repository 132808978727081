<template>
  <div class="container">
    <p>联系我们</p>
    <div class="content">
      <p class="content-p">
        Perohub是杭州尘埃科技有限公司旗下的品牌。<br>如果您对我们的产品感兴趣、寻求合作或者您对产品使用有过程任何疑问或建议，欢迎您通过以下联系方式和我们取得联系。
      </p>
      <p class="content-p">
        简历投递：<a href="mailto:jobs@tinydust.cn">jobs@tinydust.cn</a>
      </p>
      <p class="content-p">
        合作咨询：<a href="mailto:hi@tinydust.cn">hi@tinydust.cn</a>
      </p>
      <p class="content-p">
        联系电话：<a href="tel:0571-86732763">0571-86732763</a>
      </p>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  metaInfo: {
    title: "联系我们",
  },
  data () {
    return {
      selected: 0,
      showUserInfo: true
    }
  },
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";


  .container{
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;

    > p {
      font-size: 22px;
      text-align: left;
      color: @main;
      border-bottom: 1px solid @border;
    }
    .content {
      margin-top: 28px;

      .title {
        font-size: 14px;
        font-weight: bold;
        color: @black;
        margin-bottom: 4px;
      }

      .content-p {
        font-size: 14px;
        line-height: 1.71;
        color: @black;
        margin-bottom: 20px;

        a {
          display: inline;
          color: @blue;
          font-weight: bold;
        }
      }
    }
  }
  @media (min-width: 800px) {
    .container {
      max-width: 664px;
      min-width: 512px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 20px;
    }

  }
</style>
