<template>
  <div class="oos-cover">
    <h1>PEROHUB WARNING</h1>
    <p>
      We're sorry, but the content is not available in your region.
      Please make sure to follow your local laws.
      The responsiblity to any related consequeces are on your side.
      <span
        v-if="isOnMobile"
        class="touchable"
        @click="$router.back()">Tap here to go back.</span>
      <span
        v-else
        class="touchable"
        @click="$router.back()">Click here to go back.</span>
    </p>
  </div>
</template>


<script>
import { useResponsive } from '@/composables/responsive'

export default {
  setup () {
    return {
      ...useResponsive()
    }
  }
}
</script>

<style lang="less">
@import "@/assets/base.less";

.oos-cover {
  min-height: 30em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: #212121;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
  gap: 1em;
  padding: 1em;
  padding-top: 30vh;
  box-sizing: border-box;

  h1 {
    font-weight: 900;
    font-size: 26px;
    color: white;
    background-color: #b00020;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 6px;
    white-space: nowrap;
  }

  p {
    padding-left: 1em;
    padding-right: 1em;
    margin-top: 20px;
    font-size: 20px;
    color: white;

    @media @desktop {
      padding-left: 2em;
      padding-right: 2em;
    }
  }

  span {
    margin-left: 0.2em;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>