export const ja = {
  follow: 'フォロー',
  followed: 'フォロー中',
  followers: 'フォロワー',
  following: 'フォロー',
  works: '作品',
  getInTouch: '連絡先',
  latest: '時間順',
  hot: '人気順',
  free: '無料',
  searchbarPlaceholder: '作品、ユーザーとチャンネルを検索',
  users: 'ユーザー',
  channels: 'チャンネル',
  wallet: 'ウォレット',
  logout: 'ログアウト',
  add: '追加',
  added: '追加済',
  report: 'レポート',
  coins: 'コイン',
  loadmore: 'さらに表示',
  loading: '読み込み中...',
  nomore: '以上です',
  login: 'ログイン',
  getCaptcha: 'コード獲得',
  enterCaptcha: '確認コード入力ください',
  timeleft: 'あと {time} 秒',
  loginToPR: 'Perohub ログイン',
  check: '新作をチェック',
  charge: 'チャージ',
  mycoins: '私のコイン',
  purchased: '購入した作品',
  mywallet: 'ウォレット',
  updateProfile: 'プロファイル編集',
  updatePhone: '携帯電話',
  verification: 'オリジナル認証',
  postDetail: '作品詳細',
  comment: 'コメント',
  moreOfHer: '{username} の作品もっと見る',
  syncWithApp: '購入履歴を保存',
  back: '戻る',
  success: '成功',
  fail: '失敗',
  paymentResult: '支払い結果',
  payment: '支払い',
  banMessage: '{username}は「{reason}」で禁止されました',
  products: '製品',
  uploader: 'アップロードツール',
  downloadApp: 'アプリダウンロード',
  about: '関して',
  rules: 'コミュニティガイドライン',
  tos: '利用規約',
  links: 'リンク',
  reportCenter: '報告センター',
  reply: '返事',
  send: '送信',
  repliesTotal: '{total} 件の返信があります',
  commentOf: '{username}のコメント',
  enterComment: 'コメントを入力する',
  pleaseLogin: 'まずログインください',
  nomoreComment: 'コメントはありません',
  discountQuote: '割引で{price}',
  starWithCount: 'スター（{starCount}）',
  star: 'スター',
  payout: '引出',
  confirm: '確認',
  payoutApplied: '引き出しは申し込み済',
  manga: '漫画',
  photo: '写真',
  joinedCircles: '参加した応援団',
  circles: '全て応援団',
  viewAll: '全て閲覧',
  joinCircle: '{title}に参加する',
  month: '月',
  week: '週',
  day: '日',
  circleFrequency: '每{period}に {count} 更新',
  preparing: '準備中',
  updating: '連載中',
  completed: '終了',
  join: '参加',
  dm: 'DM',
  overview: '目次',
  joined: '参加済',
  mypage: 'プロファイル',
  myworks: '私の作品',
  share: '共有',
  collectInto: 'チャンネルに追加',
  addToCircle: '応援団に追加',
  applyVerify: 'クリエーター申し込み',
  publish: '作品を発表する',
  notifications: 'お知らせ',
  modify: '編集',
  bind: 'バインド',
  nickname: 'ニックネーム',
  inputNickname: 'ニックネームを入力する',
  save: '保存',
  sendOK: '送信しました',
  viewMore: 'もっと見る',
  edit: '編集',
  cancel: 'キャンセル',
  delete: '削除',
  recommendedSth: 'おすすめの{item}',
  circle: '応援団',
  topic: 'トピック',
  allTopics: '全てのトピック',
  allRecommendedUsers: '全てのおすすめのユーザー',
  latestPicks: '最新の収録',
  channelOwner: '所有者',
  subscribe: 'サブスク',
  best: '最高',
  adds: '{count} 購読',
  chooseStarItem: 'スター物件を選ぶ',
  blacklist: 'ブロックリスト',
  settings: '設置',
  creator: 'クリエーター',
  gender: '性別',
  male: '男',
  female: '女',
  secret: '秘密',
  brief: '簡単紹介',
  setAutoreply: '有料連絡先を設定',
  chatPrice: '連絡先の価格',
  resetChat: '連絡先を削除',
  buyers: '購入者',
  collect: '追加',
  createChannel: 'チャンネル作成',
  channelManagement: 'チャンネル管理',
  channelTitle: 'チャンネル名',
  inputChannelTitle: 'チャンネル名入力',
  needVerify: '投稿レビュー',
  setRoyaltyRate: '収益分配',
  channelDescription: 'チャンネル紹介',
  pleaseInputChannelDescription: 'チャンネル紹介を入力',
  submit: '申し込み',
  contactBuyers: '私に連絡したい人',
  openCircle: '応援団開設',
  circleTitle: '応援団名',
  inputCircleTitle: '応援団名入力',
  circleDescription: '宣伝文句',
  circleIntroduction: '応援団紹介',
  inputCircleDescription: '宣伝文句入力',
  inputCircleIntroduction: '応援団紹介入力',
  clickBelowToUploadCircleCover: 'カバーはまだありません',
  uploadCover: 'カバーをアップロード',
  recurringPrice: '月額',
  upfrontPrice: '完結後の一括価格',
  keizaiTimeLength: '連載時間（時間切ると一括価格だけできる）',
  year: '一年',
  halfYear: '半年',
  quarter: 'ワンシーズン',
  month: '月',
  oneMonth: '一か月',
  forever: '長い時間',
  cannotComplete: '完結になれない',
  circleType: '応援団タイプ',
  shuffle: 'シャッフル',
  promote: 'プロモーション',
  circleAdInPost: '{price}で一月中の作品見放題',
  circleAdInPostFreeComplement: '每月{price}で、{duration}の作品見放題',
  circleAdInPostUpfront: '{price}で、{duration}の作品見放題',
  artworkChannel: '以下のチャンネルに追加済',
  contribute: '投稿',
  submittedBy: '投稿者',
  approve: '許可',
  reject: '拒否',
  removeFromChannel: 'チャンネルに削除',
  review: '投稿レビュー',
  artworkDetail: '作品詳細',
  block: 'ブロック',
  unblock: 'ブロックを解除',
  clearCache: 'キャッシュをクリア',
  payToUnlock: '{price}で解禁',
  willPublishR18: '成人向けの作品を発表するかねない',
  communityGuideline: 'コミュニティガイドライン',
  topArtwork: '固定表示する',
  untopArtwork: '非固定表示する',
  editMemberTag: 'メンバーカードを編集',
  cancelNextMonth: 'サブスクを取り消す',
  circleMembers: '応援団メンバー',
  completeCircle: '応援団を完結にする',
  channelDetail: 'チャンネル詳細',
  search: '検索',
  noUsersFound: '関連するユーザーが見つかりません',
  noChannelsFound: '関連するチャンネルが見つかりません',
  noPostsFound: '関連する作品が見つかりません',
  viewBangou: '第 {no} 番',
  artworkTitle: '作品タイトル',
  artworkContent: '作品文字紹介',
  freeCover: '無料',
  artworkType: '作品種類',
  canBeCollected: 'チャンネルに収録できる',
  referencePlaceholder: 'ソース(必須)',
  referenceTip: '転載するには、ソースを示し、関連する許可を取得する必要がある',
  reference: '転載',
  original: 'オリジナル',
  pleaseChoose: '選択ください',
  dontAddToCircle: '応援団に追加しません',
  automaticEpisode: '普通回番',
  extraEpisode: '番外',
  autoDelete: '自動削除',
  autoDeleteString: '削除あと {timeString} クリックで変更',
  clickToSelect: 'クリックで選択',
  autoSetRest: '{currency}から全て両替',
  setFree: '無料と設定する',
  overview: '概要',
  detail: '明細',
  whyRejectPayout: 'なぜ引き出しを断られますか',
  yesiam18: 'はい、十八歳以上',
  no: 'いいえ',
  bindPayout: '引出先申し込み',
  copyLink: 'リンクをコピー',
  allReports: '全てのレポート',
  myReports: '私のレポート',
  reportsToMe: '私にレポート',
  privacyTerms: 'プライバシー',
  disclosure: '《特定商取引法》情報',
  submitToChannels: 'チャンネルに投稿',
  discount: '割引',
  collectRequest: '作品追加リクエスト',
  coworkRequest: '共同創作リクエスト',
  changeTitle: '作品タイトル編集',
  submitToTopics: 'トピックに投稿',
  shareChannel: 'チャンネル共有',
  buyGiftCode: 'ギフトコードを買う',
  useGiftCode: 'ギフトコードを使う',
  joinThisCircle: 'この応援団に参加：',
  payoutBindTip: 'Please choose your bank location. Unreal information will cause payout failure.',
  notPurchasedYet: 'まだ購入済ず',
  mangaDetail: '漫画詳細',
  username: 'ユーザーネーム',
  inputUsername: 'ユーザーネームを入力する',
  artworkCategories: '作品分類',
  nothingHere: 'ここは何もありません',
  categoryTitle: '分類名',
  addCategory: '分類を作成する',
  orders: 'オーダー',
  totalAmount: '合わせて',
  giftcode: 'ギフトコード',
  contentInPast: '今までのコンテンツ',
  contentSinceNow: 'これからのコンテンツ',
  tillSerializationOver: '連載終了まで',
  artworkOnDate: '{date}の作品',
  stillProcessing: 'ただいま作品が処理中です、このページを閉じてもかまいません',
  verifyNotice: 'According to regulations of USA, business like Perohub needs to do KYC(Know Your Customer) verification. Perohub does NOT store your personal information. Verification is securely processed by Veriff.',
  verifyBonus: '<b>部分地区用户如果仍然担心个人安全，可以推特大号联系 <a class="outline" href="https://twitter.com/perohubcom" target="_blank">@perohubcom</a> 跳过认证</b>',
  notifySetting: 'お知らせ設置',
  authorFollowMe: 'クリエーターが私をフォローした',
  authorNewPost: 'フォローしたクリエーター新たな作品を',
  commentNew: '新たなコメント',
  authorSubmitMyChannel: 'クリエーターが私のチャンネルに',
  channelNoPosts: 'チャンネルにはまだ作成がありません',
  searchChannels: '追加したいチャンネルを検索する',
  addedChannels: '追加したチャンネル',
  pastContentPerMonth: '過去内容の単価',
  ifCustomerJoinsAtMonth: '応援者が{index}ヶ月目に参加しなら',
  theCustomerNeedsToPay: '以下の価格が支払われる',
  customerPricePreview: '応援者価格プレビュー',
  circleHasCompleted: '応援団が既に完結した',
  illustration: 'イラスト',
  outofservice: 'あなたの地域で表示されません',
  selectChapters: '章を選択',
  nextChapter: '次回',
  prevChapter: '前回',
  alreadyFirstPage: '最初のページ',
  useWatermark: 'ダイナミック透かし（閲覧者IDを表示）',
  useAutoTranslation: '自動翻訳を利用',
  useManualTranslation: 'ほかの言語のバージョンを編集',
  aisatuTokenTitle: 'あなたの挨拶暗号は {code} です',
  aisatuUnlockText: '{price}で「{nickname}」の連絡先を獲得しますか',
  searchAisatuToken: '挨拶暗号を検索',
  givenName: '名',
  lastName: '姓',
  forceComplement: '参加時に過去の全ての月额を補充価格で強制的に支払う',
  complementPrice: '補充月额価格',
  whenUserJoins: '新しいメンバーが参加する時',
  circlePriceText: 'このメンバーが当月またはこれからの毎月{priceText}を支払う必要です',
  circleComplementPriceText: 'このメンバーこれまでのすべてのコンテンツ解禁しなければならない、価格が毎月{priceText}',
  circleZeroComplementPriceText: 'このメンバーがこれまでのすべてのコンテンツを無料で解禁します',
  circleNonForceComplementPriceText: 'これまでのコンテンツ毎月{priceText}で買うかどうか、このメンバーが後で自由に決めます',
  circleUpfrontPriceText: 'もしこの時{ title }が完結と、このメンバーが{priceText}ですべてのコンテンツ買わなければならない',
  selectFreeCoverTip: '画像をクリックで無料カバーを切り替え',
  zh: '汉语',
  en: 'English',
  ja: '日本語',
  ko: '한국인',
  requiresCircleRight: '応援団限定',
  onlyForMembers: '応援団限定',
  siteLang: 'サーイト言語',
  complement: '過去の内容を買う',
  fromChannel: '{owner}のチャンネル「{title}」から',
  enablePushNotification: 'プッシュ通知を有効にする',
  notifcationDeniedWarning: 'Perohubに対するブラウザの通知権限が拒否されたので、プッシュ通知が受信できません。',
  oneArtistMaxPresentsInLatest: 'タイムラインに作者毎に表示する作品の最大数',
  limitArtistDisplay: 'タイムラインに作者毎に表示する作品数を制限する',
  allChapters: '{count} 章',
  reversedOrder: '新しい順',
  normalOrder: '古い順',
  article: '記事',
  addTextBlock: '有料可能のテキストを追加',
  unfree: '有料',
  createOnPerohub: 'Perohubで創作する',
  promoteLineOne: 'アーティストの皆さん、受動的収入を得るのに最適なプラットフォームです！',
  globalPaymentsSupport: '全世界からの支払いをもらえる',
  displayOnWebsite: '直接サイトに表示し、追加の発送は不要',
  getPaidMonthly: '月額サブスクで支援を受け取る',
  sellSingleItems: '単品を販売する',
  getPaidForPast: 'サブスクは過去のコンテンツを補充することができる',
  alipaySupport: '顧客はAlipayとWeChat Payで支払える',
  watermarkSupport: '無断転載防止の透かし',
  realtimePayout: 'リアルタイム引き出し、人間の承認なし',
  usdtPayout: 'USDTで引き出し、安全で匿名',
  directSupport: 'サイト開発者からの直接サポート',
  bankTransfer: '口座振替',
  threedasset: '3Dアセット',
  canDownload: 'ダウンロードできる',
  downloads: 'ダウンロード',
  downloadCreated: 'Zip ダウンロードの任務を始めました',
  goToDownloads: '任務のページへ',
  refresh: '再読み込み',
  unlockCirclePrompt: '{title}に参加、もっと作品を解禁しますか、それともこれだけですか',
  unlockCirclePromptAccept: '応援団に参加',
  unlockCirclePromptReject: 'この作品だけを解禁',
  visitorMode: '他の方から見ると',
  inVisitorMode: '訪問者として閲覧中',
  visitorModeExplain: '低い解像度の画像を表示し、本当のぼかし画像は本番の場合で表示されます',
  paymentComplete: '支払い完了してから押す',
  zipTaskAutoDeleteTip: 'ダウンロードタスクが定期的に削除されます',
  r18tip: 'このペジは成人向けの内容が含まれているかもしれません、18歳未満の方はすぐに退出してください',
  forYou: 'おすすめ',
  useAlgoTimeline: 'おすすめを表示する',
  removeFromCircle: '応援団から削除',
  grantCircleRight: '応援団権限を付与',
  grantUser: 'ユーザに権限を付与',
  grantAccessTo: '{title}の権限を付与',
  hideStars: 'スターを隠す',
  showStars: 'スターを示す',
  alipay: 'Alipay',
  wechatPay: 'Wechat Pay',
  tryRefreshPage: 'ページを再読み込んでください',
  addMedia: 'メディアを追加',
  loginAutoCreate: '初めてログインすると自動的にアカウントが作成されます',
}