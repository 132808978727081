//@ts-check
import { defineStore } from "pinia"
import Vue, { ref } from "vue"
import { useApplication } from "./application"
import systemAPI from "@/api/system"
import localforage from "localforage"
import { eventEmitter } from "@/utils"
import { events } from "@/utils/events"
import { useToast } from "@/composables/toast"
import { getSelf } from "@/api/user"
import mixpanel from 'mixpanel-browser'
import { api } from "@/api/base"
import { useOneSignal } from "@/composables/onesignal"


export const ACCESSTOKEN_KEY = 'accessToken'
export const ACCESSTOKEN_HEADER_KEY = 'access-token'


export const useAuthenticator = defineStore('authenticator', () => {
  const application = useApplication()
  const authenticated = ref(false)
  const currentUser = ref({})
  const { attachExternalId } = useOneSignal()
  const { Toast } = useToast()

  
  const setAccessToken = async function (token) {
    await localforage.setItem(ACCESSTOKEN_KEY, token)
    // @ts-ignore
    Vue.http.headers.common[ACCESSTOKEN_HEADER_KEY] = token
    api.defaults.headers[ACCESSTOKEN_HEADER_KEY] = token
  }


  const checkAuth = async function (showLoginIfFailed = false) {
    try {
      if (authenticated.value) {
        return
      }
  
      const token = await localforage.getItem(ACCESSTOKEN_KEY)
      if (token) {
        const user = await getSelf(token)
        currentUser.value = user
        mixpanel.identify(user._id)
        mixpanel.people.set(user.nickname)
        authenticated.value = true
        await attachExternalId(user._id, user.nickname)
      } else {
        if (showLoginIfFailed) {
          application.showLogin()
        }
        authenticated.value = false
        currentUser.value = {}
      } 
    } catch (err) {
      console.error(err)
      Toast({
        message: err.message ? err.message : err,
      })
    }
  }


  const login = async function ({ phoneNumber, rawPhoneNumber, captcha }) {
    application.updateLoginVisible(false)

    try {
      const code = await systemAPI.verify(phoneNumber, rawPhoneNumber, captcha)
      const { accessToken } = await systemAPI.getAccessToken(code)
      await localforage.setItem(ACCESSTOKEN_KEY, accessToken)
      // @ts-ignore
      Vue.http.headers.common[ACCESSTOKEN_HEADER_KEY] = accessToken
      api.defaults.headers[ACCESSTOKEN_HEADER_KEY] = accessToken
      const user = await getSelf()
      currentUser.value = user
      mixpanel.identify(user._id)
      mixpanel.people.set(user.nickname)
      authenticated.value = true
      eventEmitter.emit(events.LoginCompleted)
      await attachExternalId(user._id, user.nickname)
    } catch (err) {
      console.error(err)

      Toast({
        message: err.message ? err.message : err,
      })
    }
  }


  const logout = async function () {
    try {
      localforage.removeItem(ACCESSTOKEN_KEY)
      // @ts-ignore
      Vue.http.headers.common[ACCESSTOKEN_HEADER_KEY] = ''
      authenticated.value = false
      currentUser.value = {}
      mixpanel.reset()
      eventEmitter.emit(events.LogoutCompleted)
    } catch (err) {
      console.error(err)

      Toast({
        message: err.message ? err.message : err,
      })
    }
  }

  const refreshUser = async function () {
    try {
      if (!authenticated.value) {
        application.showLogin()
        return
      }
      const token = await localforage.getItem(ACCESSTOKEN_KEY)
      const user = await getSelf(token)
      currentUser.value = user
      mixpanel.identify(user._id)
      mixpanel.people.set(user.nickname)
    } catch (err) {
      console.error(err)
      Toast({
        message: err.message ? err.message : err,
      })
    }
  }

  return {
    login,
    logout,
    authenticated,
    refreshUser,
    checkAuth,
    currentUser,
    setAccessToken,
  }
})