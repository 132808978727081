<template>
  <!-- desktop -->
  <div
    v-if="!isOnMobile"
    class="circles-container">
    <ul
      v-show="showNav"
      class="left">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ active: $route.path === tab.path }"
        @click="select(index)">
        {{ tab.title }}
      </li>
    </ul>

    <div class="right">
      <keep-alive>
        <router-view class="view" />
      </keep-alive>
    </div>
  </div>


  <!-- mobile -->
  <div
    v-else
    class="mobile-container">
    <div class="mobile-navbar">
      <!-- <img
        v-if="authenticated"
        class="chat"
        src="@/assets/Navigation_Chat@2x.png">
      <span
        v-if="false"
        class="tip">{{ 0 }}</span> -->

      <mt-navbar
        v-model="selected"
        class="segmented-control">
        <mt-tab-item
          :id="0"
          class="touchable"
          :class="{ hoverable: !isOnMobile }">
          {{ $t('joinedCircles') }}
        </mt-tab-item>
        <mt-tab-item
          :id="1"
          class="touchable"
          :class="{ hoverable: !isOnMobile }">
          {{ $t('circles') }}
        </mt-tab-item>
      </mt-navbar>
    </div>

    <mt-tab-container
      v-model="selected"
      :swipeable="true">
      <mt-tab-container-item :id="0">
        <div
          v-if="authenticated"
          class="circles">
          <circle-cell
            v-for="circle in myCircles"
            :key="circle._id"
            class="touchable hoverable circle"
            :circle="circle"
            @click.native="$router.push(`/circles/${circle._id}`)"></circle-cell>
          <load-more-button
            :busy="myCirclesLoading"
            :has-more="myCirclesHasmore"
            :trigger="fetchMyCircles"></load-more-button>
        </div>

        <login-cover
          v-else></login-cover>
      </mt-tab-container-item>

      <mt-tab-container-item :id="1">
        <div
          v-for="kind in circleKinds"
          :key="kind._id"
          class="circles">

          <!-- <div class="kind">
            <h2>{{ kind.title }}</h2>
            <span
              class="touchable hoverable"
              @click="$router.push(`/circle/topic?type=${kind.category}`)">
              {{ $t('viewAll') }}
              <img src="@/assets/icon_page_nextpage_grey.png">
            </span>
          </div> -->

          <circle-cell
            v-for="circle in kind.groups"
            :key="circle._id"
            class="touchable hoverable"
            :circle="circle"
            @click.native="$router.push(`/circles/${circle._id}`)"></circle-cell>
        </div>
      </mt-tab-container-item> 
    </mt-tab-container>
  </div>
</template>

<script>
import { useResponsive } from '@/composables/responsive'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import CircleCell from '@/components/CircleCell.vue'
import LoginCover from '@/components/LoginCover.vue'
import { useCircleKind } from '@/composables/circle_kind'
import { useCurrentUser } from '@/composables/user_data'
import { useMyCircles } from '@/composables/circle_my'
import { ref } from 'vue'


export default {
  metaInfo: {
    title: 'Perohub | Artworks Marketplace',
  },
  setup () {
    return {
      selected: ref(1),
      ...useResponsive(),
      ...useCircleKind(),
      ...useCurrentUser(),
      ...useMyCircles(),
    }
  },
  computed: {
    showNav () {
      return this.tabs.map(tab => tab.path).includes(this.$route.path)
    },
    tabs () {
      const tabs = [{
        title: this.$t('joinedCircles'),
        path: '/circles/my'
      }, {
        title: this.$t('circles'),
        path: '/circles/all'
      }]

      return tabs
    }
  },
  methods: {
    select (index) {
      this.index = index
      this.$router.push(this.tabs[index].path) 
    }
  },
  components: {
    LoadMoreButton,
    CircleCell,
    LoginCover,
  }
}
</script>

<style lang="less" scoped>
@import '@/assets/base.less';

.circles-container {
  margin-top: 14px;
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;

  .left {
    width: 120px;
    margin-right: 40px;
    vertical-align: top;
    margin-left: 40px;


    li {
      display: block;
      height: 30px;
      width: 120px;
      color: #cfcfcf;
      font-size: 14px;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 10px;
      text-align: center;

      &.active {
        color: #9b9b9b;
        border-radius: 15px;
        background-color: #f1f1f1;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .right {
    flex: 1;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
  }
}

.view {
  min-height: 400px;
}

@media @phone {
  .kind {
    position: relative;
    margin-bottom: 10px;
    h2 {
      font-size: 23px;
      font-weight: normal;
      color: #4a4a4a;
    }

    span {
      position: absolute;
      right: 4px;
      font-size: 14px;
      color: #cfcfcf;
      top: 3px;

      img {
        width: 15px;
        height: 15px;
        margin-left: 6px;
        position: relative;
        top: 2px;
      }
    }
  }
  .mobile-container {
    padding-bottom: 60px;
  }

  .circles {
    padding-left: 10px;
    padding-right: 10px;
    // margin-top: 16px;
    // margin-bottom: 30px;

    .circle {
      margin-bottom: 20px;
    }
  }

  .mobile-navbar {
    position: relative;

    .chat {
      position: absolute;
      width: 28px;
      height: 24px;
      left: 4%;
      top: 2px;
    }
    .tip {
      background: red;
      position: absolute;
      top: 0px;
      left: 9%;
      font-size: 10px;
      color: white;
      padding-left: 3px;
      padding-right: 3px;
      border-radius: 50%;
      padding-top: 0px;
      padding-bottom: 0px;
      max-height: 14px;
      line-height: 14px;
    }
  }

  .segmented-control {
    margin-right: 10px;
    margin-bottom: 10px;
    width: 70%;
    margin-left: 15%;
  }
}
</style>
