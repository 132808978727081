import Vue from 'vue'
import { API_URL, clientId, clientSecret } from '../config'
import { api } from './base'
import { isOnMobile } from '@/utils'

export const fetchConfig = async function () {
  try {
    const res = await api.get('/configs')

    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getProcessors = async function () {
  try {
    const res = await api.get('/configs/processors')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const sendSMS = async function (phoneNumber, rawPhoneNumber) {
  try {
    const res = await api.post('/users/sms', {
      phoneNumber,
      rawPhoneNumber
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export default {
  verify (phoneNumber, rawPhoneNumber, captcha) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${API_URL}/oauth2/code`, {
          phoneNumber,
          rawPhoneNumber,
          responseType: 'code',
          captcha,
          clientId: isOnMobile() ? clientId.mobile : clientId.desktop
        })
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result.code)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  getAccessToken (code) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${API_URL}/oauth2/access_token`, {
          clientId: isOnMobile() ? clientId.mobile : clientId.desktop,
          clientSecret: isOnMobile() ? clientSecret.mobile : clientSecret.desktop,
          grantType: 'authorization_code',
          code
        })
        .then(({ ok, data }) => {
          if (ok) {
            Vue.http.headers.common['access-token'] = data.accessToken

            resolve({
              accessToken: data.result.accessToken,
              refreshToken: data.result.refreshToken,
              expiredDate: data.result.expiredDate
            })
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  fetchConfig () {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/configs`)
        .then(({ ok, data }) => {
          if (ok && data.code === 200) {
            return resolve(data.result)
          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  }
}
