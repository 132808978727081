<template>
  <div class="wrap">
    <circle-cell-desktop
      v-for="circle in allCircles"
      :key="circle._id"
      class="circle"
      :circle="circle"
      @click.native="$router.push(`/circles/${circle._id}`)"></circle-cell-desktop>
    <!-- <div
      v-for="category in circleKinds"
      :key="category._id"
      class="wrap-he">
      <div class="kind">
        <h2>{{ category.title }}</h2>
        <span
          class="touchable hoverable"
          @click="$router.push({
            name: category.category === 'comics' ? 'CircleListComics' : 'CircleListPhotos'
          })">
          {{ $t('viewAll') }}
          <img src="@/assets/icon_page_nextpage_grey.png">
        </span>
      </div>

      <mobile-circle-cell
        v-for="circle in allCircles"
        :key="circle._id"
        class="circle"
        :circle="circle"
        @click.native="$router.push(`/circles/${circle._id}`)"></mobile-circle-cell>
    </div> -->
  </div>
</template>

<script>
import CircleCellDesktop from '@/components/CircleCellDesktop.vue'
import { useAllCircles } from '@/composables/circle_all'
import { useCircleKind } from '@/composables/circle_kind'
import { useCurrentUser } from '@/composables/user_data'


export default {
  setup () {
    return {
      ...useAllCircles(),
      ...useCircleKind(),
      ...useCurrentUser(),
    }
  },
  data () {
    return {
      selected: 0,
    }
  },
  components: {
    CircleCellDesktop,
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";
.kind {
  position: relative;
  margin-bottom: 10px;
  h2 {
    font-size: 23px;
    font-weight: normal;
    color: #4a4a4a;
  }

  span {
    position: absolute;
    right: 4px;
    font-size: 14px;
    color: #cfcfcf;
    top: 3px;

    img {
      width: 15px;
      height: 15px;
      margin-left: 6px;
      position: relative;
      top: 2px;
    }
  }
}

.wrap-he {
  overflow: hidden;
  margin-bottom: 40px;
}
.wrap {
  padding: 0px 0px 0px 0px;
  width: 800px;
  flex-flow: row wrap;
  align-content: flex-start;

  .circle {
    float: left;


  }
  .circle:hover {
    background-color: rgba(0, 0, 0, 0.01);
    opacity: 1;
  }
}
</style>
