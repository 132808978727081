import { Toast, Indicator } from 'mint-ui'
import * as types from '../mutation-types'
import star from '../../api/media'
import { t } from '@/translations'

export const fetchAlbums = ({ commit, state }, { page, limit }) => {
  Indicator.open()

  return star
    .albums(page)
    .then(albums => {
      albums.forEach(album => {
        album.ischeck = false
      })

      Indicator.close()
      commit(types.PUSH_ALBUMS, albums)

      const hasMore = albums.length !== 0
      return Promise.resolve(hasMore)
    })
    .catch(err => {
      console.error(err)

      Indicator.close()

      Toast({
        message: err.message ? err.message : err,
        position: 'bottom'
      })
    })
}

export const fetchPictures = ({ commit, state }, { ownerId, timestamp = (new Date().getTime()) }) => {
  Indicator.open()

  star
    .oldImages(timestamp, ownerId)
    .then(pictures => {
      pictures.forEach(album => {
        album.showcheck = false
        album.ischeck = false
      })

      Indicator.close()
      commit(types.UPDATE_PICTURES, pictures)

      if (pictures.length === 0) {
        Toast({
          message: t('nomore'),
          position: 'bottom'
        })
      }
    })
    .catch(err => {
      console.error(err)

      Indicator.close()

      Toast({
        message: err.message ? err.message : err,
        position: 'bottom'
      })
    })
}

export const clearPictures = ({ commit }) => {
  commit(types.CLR_PICTURES)
}
