<template>
  <div class="container">
    <p>产品介绍</p>
    <div class="description-pc">
      <div class="middle">
        <div class="content-wrap">
          <div class="single">
            Perohub是一个内容分享社区，用户可以轻而易举的将以图片，文字等内容为载体的作品通过我们的应用程序或网页版快速分享到这个平台上。给用户带来高质量的内容一直是我们追求的目标，为此我们邀请了很多优质的签约作者，带来高质量的付费内容。
          </div>
          <div class="single evens">
            摄影作者，漫画作者在我们平台上可以用自己的实力带来可观的收入。我们在网页版，移动端提供了评论，私信等功能，作为用户可以通过Perohub寻找自己喜欢的作者，与他们互动，交流。用自己的实际行动支持原创的内容，给平台带来一个可持续发展的基础。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'


export default {
  metaInfo: {
    title: "产品介绍",
  },
  data () {
    return {
      selected: 0,
      showUserInfo: true
    }
  },
  computed: {
    
  },
  created () {
    
  },
  methods: {
   
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.description-pc {
 


  .middle {
    width: 920px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 60px;
    box-sizing: border-box;

    .content-wrap {
      overflow: hidden;
      .single {
        width: 440px;
        height: 180px;
        font-size: 17px;
        line-height: 1.53;
        color: #9b9b9b;
        float: left;
        margin-right: 40px;
        text-align: justify;
      }

      .evens {
        margin-right: 0px;
      }
    }

    .title {
      text-align: center;
      margin-bottom: 40px;


      img {
        width: 360px;
        height: 90px;
        object-fit: cover;
      }
    }
  }
}


  .content-warp {
    margin-top: 34px;
    padding-bottom: 20px;
    border-bottom: 1px solid @border;

    .top {
      height: 20px;
      margin-bottom: 30px;

      img {
        width: 57px;
        height: 18px;
      }

      span {
        font-size: 18px;
        position: relative;
        top: -2px;
        color: #FF6686;
      }
    }

    .middle {
      overflow: hidden;

      div {
        float: left;
        width: 320px;
        margin-right: 16px;


        .title {
          color: #FF6686;
          font-size: 16px;
          margin-bottom: 10px;
        }

        .descriptiom {
          font-size: 14px;
          width: 310px;
          margin-bottom: 10px;
        }


      }

      .odd {
        margin-right: 0px;
      }

    }
  }


  .container{
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    padding-bottom: 40px;


    > p {
      font-size: 22px;
      text-align: left;
      color: @main;
      border-bottom: 1px solid @border;
    }
    .content {
      margin-top: 28px;
      margin-bottom: 50px;

      .title {
        font-size: 14px;
        font-weight: bold;
        color: @black;
        margin-bottom: 4px;
      }

      .content-p {
        font-size: 14px;
        line-height: 1.71;
        color: @black;
        margin-bottom: 20px;

        a {
          display: inline;
          color: @blue;
          font-weight: bold;
        }
      }
    }
  }
  @media (min-width: 800px) {
    .container {
      width: 920px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 20px;
    }

  }
</style>
