<template>
  <div class="wrap">
    <div class="photo">
      <div class="prshare">
        <div
          v-if="user"
          class="top-user"
        >
          <img
            class="profile"
            loading="lazy"
            :src="avatarURL"
          >
          <span class="name">{{ nickname }}</span>
          <span>向你分享了</span>
        </div>
        <div class="logotop">
          <img
            class="logo"
            src="@/assets/prshare.png"
          >
        </div>
        <p>
          <img src="@/assets/prsharewhite.png">
          <span>- 原创作品社区</span>
        </p>
        <div class="bottom">
          <a
            v-if="!isOnMobile"
            class="hoverable touchable"
            @click.prevent="downloadcode"
          >立即下载</a>
          <a
            v-if="isOnMobile"
            class="hoverable touchable"
            @click.prevent="download"
          >立即下载</a>
        </div>
      </div>
      <div class="contain">
        <div
          v-if="!isOnMobile && circles.length > 0"
          class="container"
        >
          <div class="column-4">
            <div class="unit">
              <img
                loading="lazy"
                :src="circles[18].coverUrl"
                :style="{height: imgheight + 'px'}"
              >
            </div>
            <div
              v-for="i in 8"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i].coverUrl"
                alt="无缝滚动"
              >
            </div>
            <div
              v-for="i in 10"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i].coverUrl"
                alt="无缝滚动"
              >
            </div>
          </div>
              
          <div class="column-4">
            <div
              v-for="i in 8"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i+4].coverUrl"
                alt="无缝滚动"
              >
            </div>
            <div
              v-for="i in 10"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i].coverUrl"
                alt="无缝滚动"
              >
            </div>
          </div>

          <div class="column-4">
            <div class="unit">
              <img
                loading="lazy"
                :style="{height: imgheight + 'px'}"
                :src="circles[28].coverUrl"
                alt="无缝滚动"
              >
            </div>
            <div
              v-for="i in 8"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i+8].coverUrl"
                alt="无缝滚动"
              >
            </div>
            <div
              v-for="i in 10"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i].coverUrl"
                alt="无缝滚动"
              >
            </div>
          </div>

          <div class="column-4">
            <div
              v-for="i in 8"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i+12].coverUrl"
                alt="无缝滚动"
              >
            </div>
            <div
              v-for="i in 10"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i].coverUrl"
                alt="无缝滚动"
              >
            </div>
          </div>

          <div class="column-4">
            <div class="unit">
              <img
                loading="lazy"
                :style="{height: imgheight + 'px'}"
                :src="circles[28].coverUrl"
                alt="无缝滚动"
              >
            </div>
            <div
              v-for="i in 8"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i+14].coverUrl"
                alt="无缝滚动"
              >
            </div>
            <div
              v-for="i in 10"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i].coverUrl"
                alt="无缝滚动"
              >
            </div>
          </div>

          <div class="column-4">
            <div
              v-for="i in 10"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i].coverUrl"
                alt="无缝滚动"
              >
            </div>
            <div
              v-for="i in 18"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i].coverUrl"
                alt="无缝滚动"
              >
            </div>
          </div>

          <div class="column-4">
            <div class="unit">
              <img
                loading="lazy"
                :style="{height: imgheight + 'px'}"
                :src="circles[28].coverUrl"
                alt="无缝滚动"
              >
            </div>
            <div
              v-for="i in 10"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i+4].coverUrl"
                alt="无缝滚动"
              >
            </div>
            <div
              v-for="i in 18"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i].coverUrl"
                alt="无缝滚动"
              >
            </div>
          </div>

          <div class="column-4">
            <div
              v-for="i in 10"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i+10].coverUrl"
                alt="无缝滚动"
              >
            </div>
            <div
              v-for="i in 18"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i].coverUrl"
                alt="无缝滚动"
              >
            </div>
          </div>
        </div>

        <div
          v-if="isOnMobile && circles.length > 0"
          class="container"
        >
          <div class="column-4 column-mobile">
            <div class="unit">
              <img
                loading="lazy"
                :src="circles[28].coverUrl"
                :style="{height: imgheight + 'px'}"
              >
            </div>
            <div
              v-for="i in 8"
              :key="i"
              class="unit"
            >
              <img
                :src="circles[i].coverUrl"
                loading="lazy"
                alt="无缝滚动"
              >
            </div>
            <div
              v-for="i in 20"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i].coverUrl"
                alt="无缝滚动"
              >
            </div>
          </div>
              
          <div class="column-4 column-mobile">
            <div
              v-for="i in 14"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i + 4].coverUrl"
                alt="无缝滚动"
              >
            </div>
            <div
              v-for="i in 20"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i].coverUrl"
                alt="无缝滚动"
              >
            </div>
          </div>

          <div class="column-4 column-mobile">
            <div class="unit">
              <img
                loading="lazy"
                :src="circles[29].coverUrl"
                :style="{height: imgheight + 'px'}"
              >
            </div>
            <div
              v-for="i in 14"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i+10].coverUrl"
                alt="无缝滚动"
              >
            </div>
            <div
              v-for="i in 20"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i].coverUrl"
                alt="无缝滚动"
              >
            </div>
          </div>

          <div class="column-4 column-mobile">
            <div
              v-for="i in 14"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i+14].coverUrl"
                alt="无缝滚动"
              >
            </div>
            <div
              v-for="i in 20"
              :key="i"
              class="unit"
            >
              <img
                loading="lazy"
                :src="circles[i].coverUrl"
                alt="无缝滚动"
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-show="maskshow"
      class="mask-wrap"
      @click="downloadcode"
    />
    <div
      v-show="maskshow"
      class="pop"
    >
      <div class="top">
        <img
          class="hoverable touchable"
          src="@/assets/close_red_envelope.png"
          @click="downloadcode"
        >
        <span>扫描以下二维码下载</span>
      </div>
      <div class="middle">
        <img src="@/assets/downloaderweima.png">
        <p>iOS · Android</p>
      </div>
    </div>

    <div
      v-if="!isOnMobile"
      class="detail"
    >
      <div class="ul">
        <div>
          <img src="@/assets/icon-page-comic@2x.png">
        </div>
        <div class="descri">
          <span>真实原创</span>
          <p>所有作者均经过原创认证，发现盗版盗图立即封号</p>
        </div>
      </div>
      <div class="ul">
        <div>
          <img src="@/assets/icon-page-chatting@2x.png">
        </div>
        <div class="descri">
          <span>一起造梦</span>
          <p>通过内容付费与购买衍生品支持自己喜爱的创作者</p>
        </div>
      </div>
      <div class="ul">
        <div>
          <img src="@/assets/icon-page-channel-manager@2x.png">
        </div>
        <div class="descri">
          <span>内容丰富</span>
          <p>已支持漫画与二次元写真，更多内容持续更新</p>
        </div>
      </div>
      <div class="ul">
        <div>
          <img src="@/assets/icon-page-position@2x.png">
        </div>
        <div class="descri">
          <span>推广获利</span>
          <p>通过频道分销正版内容，获得正当、共赢、不损害创作者的收益</p>
        </div>
      </div>
    </div>

    <div
      v-if="isOnMobile"
      class="wrap-mobile"
    >
      <div class="ul">
        <div>
          <img src="@/assets/icon-page-comic@2x.png">
        </div>
        <div class="descri">
          <span>真实原创</span>
          <p>所有作者均经过原创认证，发现盗版盗图立即封号</p>
        </div>
      </div>
      <div class="ul">
        <div>
          <img src="@/assets/icon-page-chatting@2x.png">
        </div>
        <div class="descri">
          <span>一起造梦</span>
          <p>通过内容付费与购买衍生品支持自己喜爱的创作者</p>
        </div>
      </div>
      <div class="ul">
        <div>
          <img src="@/assets/icon-page-channel-manager@2x.png">
        </div>
        <div class="descri">
          <span>内容丰富</span>
          <p>已支持漫画与二次元写真，更多内容持续更新</p>
        </div>
      </div>
      <div class="ul">
        <div>
          <img src="@/assets/icon-page-position@2x.png">
        </div>
        <div class="descri">
          <span>推广获利</span>
          <p>通过频道分销正版内容，获得正当、共赢、不损害创作者的收益</p>
        </div>
      </div>
      <div class="bottom">
        <a
          class="hoverable touchable red"
          @click.prevent="download"
        >立即下载</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import channel from '@/api/channel'
import { isOnMobile, isIOS, placeholder } from '@/utils'
import { ANDROID_URL } from '@/config'
import api from '@/api/circle'

export default {
  metaInfo: {
    title: "下载Perohubapp",
  },
  data () {
    return {
      user: false,
      nickname: '',
      avatarURL: '',
      showTip: false,
      isOnMobile: isOnMobile(),
      isIOS: isIOS(),
      apkurl: ANDROID_URL,
      maskshow: false,
      circles: [],
      limit: 30,
      imgheight: 80,
      placeholder,
    }
  },
  computed: {
    ...mapGetters({
    }),
  },
  mounted () {
    if (this.$route.query.partnerId) {
      this.getPartner(this.$route.query.partnerId)
    }
    if (this.isOnMobile) {
      this.imgheight = (document.documentElement.clientWidth * 0.25 - 10)/2
    } else {
      this.imgheight = (document.documentElement.clientWidth * 0.125 - 10)/2
    }
    

    console.info(this.imgheight)
    this.getAllCircles()
    
  },
  destroyed () {


  },
  methods: {
    getAllCircles () {
      api
        .getAllCircles(1, this.limit)
        .then(circles => {
          this.circles = circles 
        })
        .catch(err => {

          console.error(err)

          this.$toast({
            position: 'bottom',
            message: err.message ? err.message : err
          })
        })
    },
    downloadcode () {
      this.maskshow = !this.maskshow
    },
    getPartner (partnerId) {
      channel
        .fetchApkid(partnerId)
        .then(apkdetail => {
          this.apkurl = apkdetail.partner.apkUrl

          this.avatarURL = apkdetail.partner.owner.avatarURL
          this.nickname = apkdetail.partner.owner.nickname
          this.user = true
        })
        .catch(err => {

          console.error(err)
          this.user = false

          this.$toast({
            position: 'bottom',
            message: err.message ? err.message : err
          })
        })
    },
    download () {
      if (isOnMobile()) {
        if (isIOS()) {
          //let url = 'https://neicebao.com/build/5ab0de802ab5e1bb6581eb95'
          this.$router.push('/downloadios')
          //window.open(IOS_IPA_URL)
          /*if (this.$route.query.partnerId) {
            url = `${IOS_URL_Download}pt=118819544&mt=8&ct=${this.$route.query.partnerId}`
          } else {
            url = `${IOS_URL_Download}pt=118819544&mt=8`
          }
          // window.open(url)
          return this
          .$messagebox({
            title: 'App Store 暂未上架',
            message: 'App Store 暂未上架。您可以先使用网页版支持自己喜欢的作者',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: '去首页',
            cancelButtonText: '取消'
          })
          .then(action => {
            if (action === 'confirm') {
              location.href = APP_URL
            }
          })
          .catch(err => {
            if (err !== 'cancel') {
              this.$toast({
                message: err,
                position: 'bottom'
              })
            }

            console.log(err)
          })*/

        } else {
          return window.open(this.apkurl)
        }
      } else {

        return window.open(this.apkurl)
        //return window.open("http://zhushou.360.cn/detail/index/soft_id/3234793")
      }
    }
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";


.top-user {
  padding-bottom: 20px;
  text-align: center;
  .profile {
    width: 32px;
    height:32px;
    border-radius: 32px;
    vertical-align: -8px;
  }

  span {
    color: @white;
    font-size: 17px;
    padding-left: 6px;
  }

  .name {
    font-weight: bold;
  }
}

.mask-wrap {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.13);
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.pop {
  width: 294px;
  height: 338px;
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px #e7e7e7;
  position: fixed;
  top: 30%;
  left: 40%;
  box-sizing: border-box;
  z-index: 3;

  .top {
    height: 44px;
    border-radius: 2px;
    background-color: #ffffff;
    border-bottom: solid 1px #e7e7e7;
    line-height: 44px;

    img {
      width: 18px;
      height: 18px;
      position: relative;
      top: 4px;
      margin-right: 4px;
      margin-left: 10px;
    }

    span {
      font-size: 17px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      color: #333333;
    }
  }

  .middle {
    width: 200px;
    margin: 20px auto 10px auto;
    text-align: center;

    img {
      width: 184px;
      height: 184px;
      border: solid 1px #cfcfcf;
    }

    p {
      width: 186px;
      height: 40px;
      border-radius: 20px;
      background-color: #55caff;
      font-size: 18px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      line-height: 40px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
    }
  }
}

.wrap-mobile {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 10px;

  .ul {
    width: 100%;
    float: left;
    height: 88px;
    overflow: hidden;
    border-bottom: 1px solid #f1f1f1;
    margin-top: 10px;

    div {
      float: left;
    }

    .descri {
      margin-left: 4px;
      width: 70%;

      span {
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #4a4a4a;
        padding-bottom: 10px;
      }

      p {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #9b9b9b;
        margin-top: 6px;
      }
    }

    img {
      width: 80px;
      height: 96px;
      position: relative;
      top: -10px;
      object-fit: cover;
    }
  } 
}

.photo {
  height: 720px;
  width: 100%;
  position: relative;
  

  .contain {
    height: 720px;
    overflow: hidden;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    z-index: 2;
    background-image: radial-gradient(circle at 4% 97%,rgba(211,75,255,.35),rgba(211,75,255,0)),linear-gradient(150deg,#ff9266,#ff6686);
  }

  
}



.prshare {
  position: absolute;
  width: 350px;
  height: 300px;
  text-align: center;
  left: 0; top: 0; right: 0; bottom: 0;
  margin: auto; 
  z-index: 3;


  .logo {
    width: 96px;
    height: 96px;
  }
  .bottom {

    a {
      width: 343px;
      height: 43px;
      border-radius: 4px;
      border: solid 1px #ffffff;
      font-size: 18px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: 4px;
      text-align: center;
      color: #ffffff;
      display: inline-block;
      margin-top: 72px;
      line-height: 43px;
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
  p {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    position: relative;
    top: 20px;

    img {
      width: 57px;
      height: 18px;
      position: relative;
      top: 3px;
      object-fit: cover;
    }


  }
}


.container{
  width : 100%;
  box-sizing: border-box;
  height: 400px;
  zoom : 1;
  display: table;
  padding-left: 5px;
  padding-right: 5px;
  animation: ec-marquee-move 120s infinite linear;
}

.column-4 {
  width : 12.5%;
  margin : 0;
  float : left;
}

.column-mobile {
  width : 25%;
  margin : 0;
  float : left;
}

.unit{
  height : auto;
  margin : 10px auto;
  margin-right: 5px;
  margin-left: 5px;

  img {
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0.53;
    background-blend-mode: soft-light;
    background-image: linear-gradient(#ff5579, #ff5579);
    animation: imgchange 1s linear;
  }
}

@keyframes ec-marquee-move {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-800px);
  }
}

@keyframes imgchange {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.53;
  }
}





@media (max-width: 800px) { 
  .photo {
    height: 604px;
   

    .contain {
      height: 604px;
    }
  }

  .unit{
    margin : 8px auto;
    margin-right: 4px;
    margin-left: 4px;
  }

  .bottom {

    a {
      width: 90%;
      height: 43px;
      border-radius: 4px;
      border: solid 1px #ffffff;
      font-size: 18px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: 4px;
      text-align: center;
      color: #ffffff;
      display: inline-block;
      margin-top: 52px;
      line-height: 43px;
    }
    .red {
      color: #ff6686;
      border: solid 1px #ff6686;
      width: 100%;
      margin-bottom: 60px;
      font-weight: 400;
    }
  }
}

.detail {
  width: 980px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  margin-bottom: 120px;
  overflow: hidden;

  .ul {
    width: 490px;
    float: left;
    height: 128px;
    overflow: hidden;

    div {
      float: left;
    }

    .descri {
      margin-left: 4px;
      width: 330px;
      margin-top: 36px;

      span {
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #4a4a4a;
        padding-bottom: 10px;
      }

      p {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #9b9b9b;
        margin-top: 6px;
      }
    }

    img {
      height: 128px;
      position: relative;
      object-fit: cover;
    }
  }
}




#tips_box {background:rgba(0,0,0,0.9) url(http://cdn.lydiabox.com/assets/officialsite/img/arrow.png) no-repeat top right; position:absolute; top:0; left:0; width:100%; height:100%; text-align:center; line-height:40px; padding-top:80px;}
#tips_box img {margin-left:5px; margin-right:5px; width:32px; height:32px; display:inline; vertical-align:middle;}
#tips_box button {background:none; border:1px solid #fff; border-radius:3px; color:#fff; font-size:18px; width:120px; height:50px; line-height:50px; position:absolute; bottom:140px; left:50%; margin-left:-60px; cursor:pointer;}
#tips_box {background-size:52px 40px; color: #f5f5f5;}
</style>
