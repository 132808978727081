//@ts-check
import { ref } from "vue"
import { useToast } from "@/composables/toast"
import { getMessages, clearAllMessages, getUnreadCount, updateStatus } from '@/api/message'
import { defineStore } from "pinia"
import { useCurrentUser } from "@/composables/user_data"


export const useNotificationStore = defineStore('notification', () => {
  const notifications = ref([])
  const { Toast } = useToast()
  const unreadCount = ref(0)
  const { authenticated } = useCurrentUser()


  const fetch = async function () {
    try {
      notifications.value = await getMessages()
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const fetchUnreadCount = async function () {
    try {
      if (authenticated.value) {
        unreadCount.value = await getUnreadCount()
      } else {
        unreadCount.value = 0
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const clearAll = async function () {
    try {
      await clearAllMessages()
      await fetchUnreadCount()
      notifications.value = []
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  
  const markAsRead = async function (notificationId) {
    try {
      await Promise.all([
        updateStatus(notificationId, 'read'),
        fetchUnreadCount(),
        fetch(),
      ])
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }


  return {
    unreadCount,
    fetch,
    clearAll,
    markAsRead,
    notifications,
    fetchUnreadCount,
  }
})