

// application
export const chargeVisible = state => state.application.chargeVisible
export const pulling = state => state.application.pulling
export const useTransition = state => state.application.useTransition
export const accessToken = state => state.application.accessToken
export const authenticated = state => state.application.authenticated
export const weibo = state => state.application.configs.weibo
export const feedbackURL = state => state.application.configs.feedbackURL
export const webAppURL = state => state.application.configs.webAppURL
export const driverURL = state => state.application.configs.driverURL
export const antCheckLater = state => state.application.configs.antCheckLater
export const shownav = state => state.application.shownav

export const tax = state => {
  if (state.user.current.exclusive) {
    return state.application.configs.exclusiveRoyaltyRate
  } else {
    return state.application.configs.withdrawRoyaltyRate
  }
}
export const channelTax = state => state.application.configs.royaltyRate
export const minimumYuan = state => state.application.configs.minimumWithdrawLimit
export const searchInputFocus = state => state.application.searchInputFocus
export const openId = state => state.application.openId

// posts
export const mainPosts = state => state.post.mainPosts
export const dogPosts = state => state.post.dogPosts
export const detailPost = state => state.post.detailPost
export const userPosts = state => state.post.userPosts
export const campaignPosts = state => state.post.campaignPosts
export const postDisappear = state => state.post.postDisappear

// albums
export const albums = state => state.album.albums
export const pictures = state => state.album.pictures

export const progresses = state => state.postupload.progresses

//chat
export const unreadcount = state => state.chat.UnreadCount
export const chatShow = state => state.chat.chatShow
export const privateShow = state => state.chat.privateShow
export const privateId = state => state.chat.privateId
export const initChat = state => state.chat.initChat
export const privateinitChat = state => state.chat.privateinitChat