<template>
  <div class="container">
    <p>服务内容</p>
    <div class="description-pc">
      <div class="middle">
        <div class="content-wrap">
          <div class="single">
            现阶段图片的分享是我们的核心内容，我们目的在于给作者以快速，便捷，自由的使用体验，我们为作者专门优化了上传机制，图片管理，内容编辑等等。在App上的优先需求也是为用户提供图片质量，购买和本地管理上进一步针对性的优化，这也是Perohub运营，开发至今深得用户们赞许的地方。
          </div>
          <div class="single evens">
            此外打击盗版一致是Perohub作为社交平台的一个自始自终坚持的原则，维护所有用户的权益，必要时我这们会为作者提供免费的法律途径帮助，也是我们区别于同类型竞品的最大不同。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'


export default {
  metaInfo: {
    title: "服务内容",
  },
  data () {
    return {
      selected: 0,
      showUserInfo: true
    }
  },
  computed: {
    
  },
  created () {
    
  },
  methods: {
   
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.description-pc {
 


  .middle {
    width: 920px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 60px;
    box-sizing: border-box;

    .content-wrap {
      overflow: hidden;
      .single {
        width: 440px;
        height: 180px;
        font-size: 17px;
        line-height: 1.53;
        color: #9b9b9b;
        float: left;
        margin-right: 40px;
        text-align: justify;
      }

      .evens {
        margin-right: 0px;
      }
    }

    .title {
      text-align: center;
      margin-bottom: 40px;


      img {
        width: 360px;
        height: 90px;
        object-fit: cover;
      }
    }
  }
}


  .content-warp {
    margin-top: 34px;
    padding-bottom: 20px;
    border-bottom: 1px solid @border;

    .top {
      height: 20px;
      margin-bottom: 30px;

      img {
        width: 57px;
        height: 18px;
      }

      span {
        font-size: 18px;
        position: relative;
        top: -2px;
        color: #FF6686;
      }
    }

    .middle {
      overflow: hidden;

      div {
        float: left;
        width: 320px;
        margin-right: 16px;


        .title {
          color: #FF6686;
          font-size: 16px;
          margin-bottom: 10px;
        }

        .descriptiom {
          font-size: 14px;
          width: 310px;
          margin-bottom: 10px;
        }


      }

      .odd {
        margin-right: 0px;
      }

    }
  }


  .container{
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    padding-bottom: 40px;


    > p {
      font-size: 22px;
      text-align: left;
      color: @main;
      border-bottom: 1px solid @border;
    }
    .content {
      margin-top: 28px;
      margin-bottom: 50px;

      .title {
        font-size: 14px;
        font-weight: bold;
        color: @black;
        margin-bottom: 4px;
      }

      .content-p {
        font-size: 14px;
        line-height: 1.71;
        color: @black;
        margin-bottom: 20px;

        a {
          display: inline;
          color: @blue;
          font-weight: bold;
        }
      }
    }
  }
  @media (min-width: 800px) {
    .container {
      width: 920px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 20px;
    }

  }
</style>
