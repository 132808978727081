import * as types from '../mutation-types'

const state = {
  albums: [],
  pictures: []
}

const mutations = {
  [types.UDPATE_ALBUMS] (state, albums) {
    state.albums = albums
  },

  [types.PUSH_ALBUMS] (state, albums) {
    albums.forEach(album => state.albums.push(album))
  },

  [types.UPDATE_PICTURES] (state, pictures) {
    pictures.forEach(picture => state.pictures.push(picture))
  },

  [types.CHANGE_STATUS] (state, index) {
    state.albums.forEach(album => {
      album.ischeck = false
    })
    state.albums[index].ischeck = true
  },

  [types.CHANGE_PICSTATUS] (state, flag) {
    state.pictures.forEach(album => {
      album.showcheck = flag
      album.ischeck = false
    })
  },

  [types.CHANGE_PICCHECK] (state, index, flag) {
    state.pictures[index].ischeck = !state.pictures[index].ischeck
  },

  [types.CLR_PICTURES] (state) {
    state.pictures = []
  }
}

export default {
  state,
  mutations
}
