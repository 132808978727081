//@ts-check
import mixpanel from "mixpanel-browser"
import { defineStore } from "pinia"
import { ref, watch } from "vue"


// global ui states
export const useApplication = defineStore('app', () => {
  const showChannelCollector = ref(false)
  const channelCollectorPostId = ref('')

  const showCircleCollector = ref(false)
  const circleCollectorPostId = ref('')

  const loginVisible = ref(false)

  const socialSharingVisible = ref(false)
  const socialSharingUrl = ref('')
  const socialSharingTitle = ref('')
  const socialSharingImageUrl = ref('')

  const paymentVisible = ref(false)
  const paymentPostId = ref(null)
  const paymentCircleId = ref(null)
  const paymentUserId = ref(null)
  const paymentProcessors = ref(['order2faka', 'stripe', 'wechat', 'alipay'])
  const paymentOrderId = ref(null)
  const paymentMonthNumbers = ref([])

  const browserVisible = ref(false)
  const browserMedias = ref([])
  const browserArtworkId = ref(null)
  const browserIndex = ref(0)

  const openChannelCollector = function (postId) {
    showChannelCollector.value = true
    channelCollectorPostId.value = postId
  }

  const openCircleCollector = function (postId) {
    showCircleCollector.value = true
    circleCollectorPostId.value = postId
  }

  const showLogin = function () {
    loginVisible.value = true
  }

  const updateLoginVisible = function (visible) {
    loginVisible.value = visible
  }

  const openSocialSharing = function (title, url, imageUrl = '') {
    socialSharingVisible.value = true
    socialSharingTitle.value = title
    socialSharingUrl.value = url
    socialSharingImageUrl.value = imageUrl
  }

  const openPaymentPicker = function ({
    postId,
    userId,
    circleId,
    processors,
    orderId,
    monthNumbers = [],
  }) {
    paymentVisible.value = true
    paymentUserId.value = userId
    paymentPostId.value = postId
    paymentCircleId.value = circleId
    paymentProcessors.value = processors
    paymentOrderId.value = orderId
    paymentMonthNumbers.value = monthNumbers

    mixpanel.track('Intent Payment', {
      userId,
      postId,
      circleId,
      orderId,
    })
  }

  const openBrowser = function (medias, index = 0, artworkId = null) {
    browserMedias.value = medias
    browserVisible.value = true
    browserIndex.value = index
    if (artworkId) {
      browserArtworkId.value = artworkId
    }
  }

  const closeBrowser = function () {
    browserVisible.value = false
    browserMedias.value = []
    browserIndex.value = 0
  }


  watch(showChannelCollector, () => {
    if (!showChannelCollector.value) {
      channelCollectorPostId.value = ''
    }
  })

  watch(showCircleCollector, () => {
    if (!showCircleCollector.value) {
      circleCollectorPostId.value = ''
    }
  })

  watch(socialSharingVisible, () => {
    if (!socialSharingVisible.value) {
      socialSharingUrl.value = ''
      socialSharingImageUrl.value = ''
      socialSharingTitle.value = ''
    }
  })

  watch(paymentVisible, () => {
    if (!paymentVisible.value) {
      paymentCircleId.value = null
      paymentUserId.value = null
      paymentPostId.value = null
      paymentOrderId.value = null
    }
  })


  return {
    showChannelCollector,
    openChannelCollector,
    openCircleCollector,
    channelCollectorPostId,
    showCircleCollector,
    circleCollectorPostId,
    loginVisible,
    showLogin,
    openSocialSharing,
    socialSharingVisible,
    socialSharingImageUrl,
    socialSharingTitle,
    socialSharingUrl,
    paymentVisible,
    paymentCircleId,
    paymentPostId,
    paymentUserId,
    paymentOrderId,
    paymentProcessors,
    openPaymentPicker,
    updateLoginVisible,
    browserMedias,
    browserVisible,
    browserIndex,
    openBrowser,
    closeBrowser,
    browserArtworkId,
    paymentMonthNumbers,
  }
})