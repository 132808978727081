//@ts-check
// @ts-ignore
import { Indicator } from 'mint-ui'
import userapi from '@/api/user'
import { isRef, set } from 'vue'
import { useToast } from './toast'
import { useOneSignal } from './onesignal'


export const useUserFollow = function () {
  const { Toast } = useToast()
  const { optIn, isOptedIn } = useOneSignal()

  const follow = async function (userId, mutableUser) {
    try {
      Indicator.open()
      await userapi.follow(userId)
      if (isRef(mutableUser)) {
        set(mutableUser, 'followed', true)
      } else {
        mutableUser.followed = true
      }

      if (!await isOptedIn()) {
        await optIn()        
      }
    } catch (err) {
      Toast({
        message: err.message ? err.message : err,
      })
    } finally {
      Indicator.close()
    }
  }


  const unfollow = async function (userId, mutableUser) {
    try {
      Indicator.open()
      await userapi.unfollow(userId)
      if (isRef(mutableUser)) {
        set(mutableUser, 'followed', false)
      } else {
        mutableUser.followed = false
      }
    } catch (err) {
      Toast({
        message: err.message ? err.message : err,
      })
    } finally {
      Indicator.close()
    }
  }


  return {
    follow,
    unfollow,
  }
}