<template>
  <div
    v-if="post"
    class="item-container touchable"
    :class="{
      gray: post.fake
    }"
    @click.prevent.stop="$router.push(`/posts/${post._id}`)">
    <div class="item-header">
      <user-avatar
        class="item-avatar item-avatar-phoneeight"
        :class="{'item-avatar-pc-postlistitem': !isOnMobile}" 
        :user="post.user"
        :disabled="true"
        @click.stop.native="$router.push(`/users/${post.user._id}`)"></user-avatar>
      <h5
        class="item-nickname 'item-nickname-phoneeight'"
        :class="{'item-nickname-pc-postlistitem': !isOnMobile}">
        {{ nickname }}
        <circle-badge
          v-if="post.user.tag && post.user.tag.visible"
          :member-tag="post.user.tag.memberTag"
          @click.native.stop="$router.push(`/user/${post.user.tag.authorId}/circles`)"></circle-badge>
      </h5>
      <span
        class="user-avatar-span-phoneeight"
        :class="{
          'user-avatar-span-pc-postlistitem': !isOnMobile,
        }">
        {{ createdAt }}&nbsp;&nbsp;
        {{ price === $t('free') ? $t('free') : priceText }}
      </span>
    </div>

    <img
      v-if="post.fake"
      class="fake-badge"
      src="@/assets/fake_original.png"
      alt="fake">

    
    <div class="item-content">
      <p
        class="item-content-text"
        :class="{'item-content-text-pc-postlisteight': !isOnMobile}">
        {{ outofservice ? $t('outofservice') : localeTitle }}
      </p>

      <div
        class="item-indicators-container">
        <a
          class="indicator hoverable"
          @click.prevent.stop="intentStar">
          <img
            v-if="!post.starred"
            src="@/assets/star.png">
          <img
            v-else
            src="@/assets/star_click.png">
          <span>{{ post.hideStars ? '' : post.starCount }}</span>
        </a>

        <a 
          class="indicator hoverable"
          @click.prevent.stop="$router.push(`/posts/${post._id}`)">
          <img
            src="@/assets/comment.png"
            alt="">
          <span>{{ post.commentCount }}</span>
        </a>

        <a
          class="indicator hoverable"
          @click.prevent.stop="like">
          <img
            v-if="!post.liked"
            src="@/assets/like.png">
          <img
            v-else
            src="@/assets/like_click.png">
          <span>{{ post.likes }}</span> 
        </a>
      </div>
    </div>


    <more-menu
      v-model="moreMenuVisible"
      :items="menuItems">
      <span
        v-if="isOnMobile"
        class="more touchable hoverable"
        @click.prevent.stop="moreMenuVisible = !moreMenuVisible">
        <img src="@/assets/More_icon.png">
      </span>
      <span
        v-else
        class="more touchable hoverable"
        @click.prevent.stop="moreMenuVisible = !moreMenuVisible"
        @mouseover="moreMenuVisible = true"
        @mouseleave="moreMenuVisible = false">
        <img src="@/assets/More_icon.png">
      </span>
    </more-menu>

    <post-list-item-overview
      v-if="!outofservice && post.pictures.filter(media => media.type !== 2).length > 0">
    </post-list-item-overview>

    <div
      class="item-bottom-container">
      <tags :tags="post.tags"></tags>
    </div>

    <!--report-->
    <mt-popup
      v-if="reportShow"
      v-show="reportShow"
      :position="isOnMobile ? 'right' : 'middle'"
      :modal="true"
      :close-on-click-modal="true">
      <report
        :pics-component="post"
        :post-id-component="post._id"
        :nickname-component="nickname"
        :user-id-component="post.user._id"
        :url="true"
        @close="reportShow = false"></report>
    </mt-popup>

    <media-saver
      v-if="post._id"
      v-model="collectorShow"
      :post="post"
      @completed="onStarCompleted"></media-saver>
  </div>
</template>


<script>
import Tags from '@/components/Tags.vue'
import UserAvatar from '@/components/UserAvatar.vue'
import CircleBadge from '@/components/CircleBadge.vue'
import Report from '@/pages/Report.vue'
import { useArtworkPresent } from '@/composables/artwork_present'
import { useCurrentCurrency } from '@/composables/currency'
import { usePostMoreMenu } from '@/composables/artwork_moremenu'
import { useResponsive } from '@/composables/responsive'
import MediaSaver from '@/components/MediaSaver.vue'
import { useArtworkActions } from '@/composables/artwork_action'
import MoreMenu from '@/components/MoreMenu.vue'
import PostListItemOverview from '@/components/PostListItemOverview.vue'


export default {
  props: {
    post: {
      type: Object,
      required: true
    },
  },
  setup (props) {
    const { currency } = useCurrentCurrency()

    return {
      ...useArtworkPresent(props.post._id, currency, props.post),
      currency,
      ...usePostMoreMenu(props.post),
      ...useResponsive(),
      ...useArtworkActions(props.post._id),
    }
  },
  components: {
    Tags,
    UserAvatar,
    CircleBadge,
    Report,
    MediaSaver,
    MoreMenu,
    PostListItemOverview,
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";
.loading {
  position: absolute;
  width: 80%;
  height: 100%;
  top: 0px;
  text-align: center;
  z-index: 2;
  color: #333333;
  left: 10%;

  img {
    width: 80px;
    height: 80px;
    margin-bottom: 30px;
    margin-top: 30%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    box-sizing: border-box;
    text-align: center;
    z-index: 1000;
    -webkit-transition: opacity .3s linear;
    transition: opacity .3s linear;
    width: 300px;
    display: inline-block;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 10px;
    margin-bottom: 30px;
  }
}
.item-container {
  width: 100%;
  margin-top: 1em;
  background: white;
  position: relative;
  border-bottom: solid 1px @border;

  .more {
    position: absolute;
    right: 8px;
    top: 15px;

    img {
      width: 20px;
    }
  }
}

.item-header {
  text-align: left;

  span {
    position: absolute;
    left: 6.1em;
    top: 3.4em;
    font-size: 12px;
    color: @grey;
  }
  .user-avatar-span-pc-postlistitem {
    left: 5.1em;
  }

  .user-avatar-span-phoneeight {
    left: 5.1em;
  }

  .item-avatar {
    width: 50px;
    height: 50px;
    margin-left: 0.85em;
    margin-top: 0.85em;
    border-radius: 25px;
  }

  .item-avatar-pc-postlistitem {
    margin-left: 0em;
  }

  .item-avatar-phoneeight {
    margin-left: 0em;
  }

  .item-nickname {
    position: absolute;
    left: 4.6em;
    top: 1.3em;
    font-size: 15px;
    color: @black;
    font-weight: 500;
  }
  .item-nickname-phoneeight {
    left: 3.8em;
  }
  
  .item-nickname-pc-postlistitem {
    left: 4.0em;
  }

}


.item-content {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  text-align: left;
  display: flex;
  align-items: flex-start;

  .item-content-text {
    margin-left: 0.1em;
    margin-right: 1em;
    font-size: 17px;
    color: #a1a1a1;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 2;
    display: -webkit-box;          /* Required for the line-clamp to work */
    -webkit-line-clamp: 2;         /* Number of lines you want to display */
    -webkit-box-orient: vertical;  /* Required for the line-clamp to work */
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
  }

  .item-content-text-pc-postlisteight {
    margin-left: 0em;
  }
}


.item-indicators-container {
  width: 7.5em;
  display: flex;
  align-items: center;
  margin-left: auto;
  justify-content: flex-end;

  a {
    display: block;
    float: left;
    margin-right: 0.85em;
    font-size: 0.85em;
    height: 1.7em;
    line-height: 1.7em;

    &:first-child {
      margin-left: 5px;
    }
  }

  .indicator {
    display: flex;
    align-items: center;

    img {
      width: 20px;
    }

    span {
      margin-left: 0.4em;
      font-size: 12px;
      color: @grey;
    }
  }
}

.gray {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.fake-badge {
  display: block;
  position: absolute;
  top: 15px;
  right: 20px;
  width: 120px;
  z-index: 10;
}

.item-bottom-container {
  width: 100%;
  height: 1.9em;
  padding-bottom: 0.5em;
}

</style>