<template>
  <pr-popup
    v-model="visible"
    class="tron-container">
    <div class="wrappay">
      <p>
        请输入 trc20 钱包地址
        <span
          class="outline touchable hoverable"
          @click="$router.push('/i/wallet/usdt')">
          这是什么？
        </span>
      </p>
      <mt-field
        v-model="address"
        placeholder="trc20 address"></mt-field>
      <pr-button
        :loading="binding"
        @click.native="bind">
        绑定
      </pr-button>
    </div>
  </pr-popup>
</template>

<script>
//@ts-check
import PrButton from '@/components/PRButton.vue'
import { useResponsive } from '@/composables/responsive'
import { useCurrentUser } from '@/composables/user_data'
import { computed, onMounted, ref } from 'vue'
import { useAuthenticator } from '@/pinia/authenticator'
import userapi from '@/api/user'
import { useToast } from '@/composables/toast'
import PrPopup from '@/components/PRPopup.vue'


export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  setup (props, { emit }) {
    const address = ref('')
    const { currentUser } = useCurrentUser()
    const authenticator = useAuthenticator()
    const { Toast } = useToast()
    const binding = ref(false)

    const visible = computed({
      set (value) {
        emit('input', value)
      },
      get() {
        return props.value
      }
    })

    const bind = async function () {
      try {
        if (!address.value) {
          throw new Error('please input tron address')
        }

        binding.value = true
        // @ts-ignore
        await userapi.update(currentUser.value._id, { tronwallet: address.value })
        // @ts-ignore
        authenticator.currentUser.tronwallet = address.value

        Toast('绑定成功', 'ok')
        emit('completed')
        await authenticator.refreshUser()
        visible.value = false
      } catch (err) {
        Toast({
          message: err.message ? err.message : err,
        })
      } finally {
        binding.value = false
      }
    }

    onMounted(() => {
      // @ts-ignore
      address.value = currentUser.value.tronwallet
    })

    return {
      address,
      bind,
      binding,
      ...useResponsive(),
      visible,
    }
  },
  components: {
    PrButton,
    PrPopup,
  }
}
</script>

<style>
.tron-container .p-dialog {
  width: 30rem;
}

.tron-container.p-sidebar {
  height: 16rem;
}
</style>

<style scoped lang="less">
@import "@/assets/base.less";

.hoverablebtn:hover {
  cursor: pointer;
  opacity: 0.65;
}

.wrappay {
  p {
    font-size: 12px;
    color: @black;
    margin-bottom: 6px;
    padding-left: 10px;
  }
  button {
    display: block;
    margin: auto;
    margin-top: 20px;
    width: 96%;
    background-color:@main;
    color: #ffffff;
  }
}

.mint-cell-wrapper {
  border-bottom: 1px solid @grey;
}

.outline {
  color: @blue;
}
</style>
