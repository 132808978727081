<template>
  <div class="container">
    <div class="content">
      <div
        v-for="(picture, index) in pictures"
        :key="index">
        <img
          v-if="picture.type === MediaType.IMAGE"
          class="product"
          :src="picture.thum"
          @click="view(index)">
        <img
          v-if="picture.type === MediaType.VIDEO"
          class="product"
          loading="lazy"
          :src="picture.thum"
          @click="view(index)">
        <img
          v-if="picture.type === MediaType.VIDEO"
          class="video"
          src="@/assets/video_tag.png">
        <img
          v-show="picture.showcheck && !picture.ischeck"
          class="edit"
          src="@/assets/Select_Off.png">
        <img
          v-show="picture.showcheck && picture.ischeck"
          class="edit"
          src="@/assets/Select_On.png">
      </div>
      <div
        v-show="pictures.length > 0"
        class="hoverable touchable load"
        @click.prevent="more">
        <span>{{ $t('viewMore') }}</span>
      </div>
    </div>

    <div class="bottom">
      <span
        v-show="!editprocess && pictures.length > 0"
        class="hoverable touchable"
        @click="edit(true)">{{ $t('edit') }}</span>
      <span
        v-show="editprocess"
        class="red hoverable touchable"
        @click="remove">
        {{ $t('delete') }}
        ({{ dealPicture }})
      </span>
      <span
        v-show="editprocess"
        class="cancel hoverable touchable"
        @click="edit(false)">{{ $t('cancel') }}</span>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import api from '@/api/media'
import { useResponsive } from '@/composables/responsive'
import { useCurrentUser } from '@/composables/user_data'
import { useApplication } from '@/pinia/application'
import { useToast } from '@/composables/toast'
import { MediaType } from '@/utils/defines'


export default {
  metaInfo: {
    title: 'My Stars'
  },
  setup () {
    const application = useApplication()

    return {
      ...useResponsive(),
      ...useCurrentUser(),
      openBrowser: application.openBrowser,
      ...useToast(),
      MediaType,
    }
  },
  data () {
    return {
      editprocess: false
    }
  },
  computed: {
    ...mapGetters({
      pictures: 'pictures'
    }),
    dealPicture () {
      let count = 0
      this.pictures.forEach(album => {
        if (album.ischeck) {
          count++
        }
      })
      return count
    }
  },
  watch: {
    authenticated () {
      if (this.authenticated) {
        this.fetchPictures({ ownerId: this.$route.params.ownerId })
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchPictures',
      'clearPictures'
    ]),
    remove () {
      if (this.dealPicture === 0) {
        this.showToast({
          message: '请选择您要删除的图片'
        }, 'info')
        return
      }
      let pictureIds = []
      this.pictures.forEach(album => {
        if (album.ischeck) {
          pictureIds.push(album._id)
        }
      })


      api
        .albumsDelete(pictureIds)
        .then(albums => {
          console.info(albums)
          this.showToast({
            message: '取消收藏成功'
          }, 'ok')
          this.clearPictures()
          this.fetchPictures({ ownerId: this.$route.params.ownerId})
          this.edit(false)
        })
        .catch(err => {
         
          this.showToast({
            message: err.message ? err.message : err
          })
        })

    },
    edit (flag) {
      this.editprocess = flag
      this.$store.commit('CHANGE_PICSTATUS',flag)
    },
    view (index) {
      if (this.editprocess) {
        this.$store.commit('CHANGE_PICCHECK',index)
      } else {
        console.log(123)
        this.openBrowser(this.pictures, index)
      }
    },
    more () {
      const timestamp = this.pictures[this.pictures.length - 1].starredAt

      this.fetchPictures({ ownerId: this.$route.params.ownerId, timestamp })
    }
  },
  mounted () {
    if (this.authenticated && this.pictures.length === 0) {
      this.fetchPictures({ ownerId: this.$route.params.ownerId })
    }
  },
  destroyed () {
    this.clearPictures()
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.content {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 0.2em;
  padding: 0.2em;

  div {
    aspect-ratio: 1 / 1;
    position: relative;
    display: flex;

    .video {
      position: absolute;
      width: 30px;
      height: 30px;
      left: 28%;
      top: 28%;
    }

    .edit {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 1px;
      top: 1px;
    }
  }

  .load {
    border: solid 1px @blue;
    display: flex;
    justify-content:center;
    align-items:center;
    box-sizing: border-box;
    position: relative;

    span {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: @blue;

    }
  }
}

.bottom {
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: @warm-grey;
  margin-top: 20px;
  margin-bottom: 60px;
  

  span {
    width: 120px;
    height: 30px;
    border-radius: 15px;
    background-color: @main;
    display: inline-block;
    line-height: 30px;
    color: @minor;
    background-color: @border;

    &.cancel {
      border: solid 1px @grey;
    }

    &.red {
      background-color: @main;
      color: white;
      margin-right: 6px;
    }
  }
}

.container {
  height: 100vh;
  height: 100dvh;
  background: white;
  box-sizing: border-box;
}

a, img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

a {
  display: inline-block;
  line-height: 5em;
  text-align: center;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

img:nth-child(even) {
  margin-left: 1%;
  margin-right: 1%;
}

@media (min-width: 800px) {
  .container {
    margin-left: auto;
    margin-right: auto;
    min-width: 345px;
    max-width: 512px;
  }
}



</style>
